import { AssociableFulfilmentDto } from "../core/dtos/AssociableFulfilmentDto";
import { FulfilmentDto } from "../core/dtos/FulfilmentDto";

export function mergeFulfilments(
    fulfilments: { [key: string]: FulfilmentDto[] },
    associableFulfilments: { [key: string]: AssociableFulfilmentDto[] },
) {
    const mergedFulfilments: {
        [key: string]: (FulfilmentDto | AssociableFulfilmentDto)[];
    } = {};

    // Merge fulfilments
    for (const key in fulfilments) {
        mergedFulfilments[key] = fulfilments[key];
    }

    // Merge associableFulfilments
    for (const key in associableFulfilments) {
        if (mergedFulfilments[key]) {
            mergedFulfilments[key] = mergedFulfilments[key].concat(
                associableFulfilments[key],
            );
        } else {
            mergedFulfilments[key] = associableFulfilments[key];
        }
    }

    return mergedFulfilments;
}
