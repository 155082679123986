import { TaxPayerDto } from "../core/dtos/TaxPayerDto";

export const getTaxPayerIdentifier = ({
    businessName,
    name,
    surname,
    taxIdentifier,
    vatIdentifier,
}: TaxPayerDto) => {
    if (businessName) return businessName;
    if (name && surname) return `${name} ${surname}`;
    return `${taxIdentifier.toUpperCase()} - ${vatIdentifier.toUpperCase()}`;
};
