import { SxProps } from "@mui/system";
import { useTranslation } from "@onefront/react-sdk";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from "@vapor/react-material";
import { ReactElement, useState } from "react";
import { useSuccessNotification } from "../../../core/hooks/useSuccessNotification";
import { useDeleteCustomFulfilment } from "../../../core/usecases/use-delete-custom-fulfilment/useDeleteCustomFulfilment";

interface DeleteCustomFulfilmentProps {
    children?: string | JSX.Element | JSX.Element[];
    levyId: string;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
    onDelete?: (event: React.MouseEvent<HTMLButtonElement>) => unknown;
    sx?: SxProps;
}

export const DeleteCustomFulfilment = ({
    children,
    levyId,
    onCancel,
    onDelete,
    sx,
}: DeleteCustomFulfilmentProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");
    const { showTextMessage } = useSuccessNotification();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { deleteCustomFulfilment, loading } = useDeleteCustomFulfilment();

    const onClickCancelDialog = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        setIsDialogOpen(false);
        onCancel && onCancel(event);
    };

    const onClickConfirmDialog = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        const success = await deleteCustomFulfilment({ levyId: levyId });

        if (success) {
            showTextMessage(
                t("components.delete_custom_fulfilment.notification.success", {
                    defaultValue:
                        "Adempimento personalizzato eliminato con successo",
                }),
            );

            setIsDialogOpen(false);
            onDelete && onDelete(event);
        }
    };

    return (
        <>
            <Dialog open={isDialogOpen} onClose={onClickCancelDialog}>
                <DialogTitle>
                    {t("components.delete_custom_fulfilment.dialog.title", {
                        defaultValue: "Attenzione",
                    })}
                </DialogTitle>
                <Divider variant="fullWidth" />
                <DialogContent>
                    <DialogContentText>
                        {t(
                            "components.delete_custom_fulfilment.dialog.content",
                            {
                                defaultValue:
                                    "Eliminando questo adempimento, tutti i dati all'interno verranno eliminati. Questa azione è irreversibile. Desideri continuare?",
                            },
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={loading}
                        onClick={onClickCancelDialog}
                        variant="outlined"
                    >
                        {t(
                            "components.delete_custom_fulfilment.dialog.actions.cancel",
                            { defaultValue: "Annulla" },
                        )}
                    </Button>
                    <Button
                        disabled={loading}
                        color="error"
                        onClick={onClickConfirmDialog}
                        variant="contained"
                    >
                        {t(
                            "components.delete_custom_fulfilment.dialog.actions.confirm",
                            { defaultValue: "Elimina adempimento" },
                        )}
                        {loading && (
                            <CircularProgress
                                color="inherit"
                                sx={{ ml: 1 }}
                                size={16}
                            />
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
            <Box onClick={() => setIsDialogOpen(true)} sx={sx}>
                {children && children}
            </Box>
        </>
    );
};
