import { FulfilmentActivityDto } from "./FulfilmentActivityDto";

export enum FulfilmentStatus {
    PENDING = "PENDING",
    COMPLETED = "COMPLETED",
    CLOSED = "CLOSED",
    ERROR = "ERROR",
}
export interface FulfilmentDetailDto {
    _id: string;
    activities: FulfilmentActivityDto[];
    deadline: string;
    description: string;
    levyId: string;
    status: FulfilmentStatus;
    taxPayer: { name: string; taxId: string; workspaceId: string };
    title: string;
}
