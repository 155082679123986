import { useTranslation } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback } from "react";
import { toast } from "react-toastify";

type BffError = AxiosError<{ message: string }>;

const translateError = (
    error: AxiosError,
    t: (key: string, options?: any) => string,
) => {
    if (error && error.response) {
        if ((error as BffError).response?.data.message) {
            return (error as BffError).response?.data.message;
        }
        switch (error.response.status) {
            case 401:
                return t("errors.error_notifications.401", {
                    defaultValue:
                        "Non sei autenticato per accedere a questa risorsa",
                });
            case 403:
                return t("errors.error_notifications.403", {
                    defaultValue:
                        "Non sei autorizzato per accedere a questa risorsa",
                });
            case 404:
                return t("errors.error_notifications.404", {
                    defaultValue: "La risorsa richiesta non è stata trovata",
                });
            case 504:
                return t("errors.error_notifications.504", {
                    defaultValue:
                        "La risorsa richiesta non è al momento disponibile, provare a ricaricare la pagina",
                });
        }
    }
    return t("errors.error_notifications.default", {
        defaultValue: "Si è verificato un errore con il servizio richiesto",
    });
};

export const useErrorNotification = () => {
    const { t } = useTranslation("tax-manager-app");

    const showTextMessage = useCallback((message: string) => {
        toast.error(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
        });
    }, []);

    const showAxiosErrorMessage = useCallback(
        (error: AxiosError) => {
            toast.error(translateError(error, t), {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: true,
            });
        },
        [t],
    );

    return { showTextMessage, showAxiosErrorMessage };
};
