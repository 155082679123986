import { useCallback } from "react";
import { toast } from "react-toastify";

export const useWarningNotification = () => {
    const showTextMessage = useCallback((message: string) => {
        toast.warning(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
        });
    }, []);

    return { showTextMessage };
};
