import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack,
    useTheme,
} from "@vapor/react-material";
import { ReactElement, useCallback, useState } from "react";

export interface DialogWarningProps {
    dialogContentText: string;
    dialogPrimaryButtonText: string;
    dialogSecondaryButtonText: string;
    dialogTitleText: string;
    onConfirm: () => void;
    onDismiss: () => void;
}

export const DialogWarning: React.FC<DialogWarningProps> = ({
    dialogContentText,
    dialogPrimaryButtonText,
    dialogSecondaryButtonText,
    dialogTitleText,
    onConfirm,
    onDismiss,
}): ReactElement => {
    const theme = useTheme();

    const [open, setOpen] = useState(true);

    const handleDialogClose = useCallback(
        (_?: object, reason?: string) => {
            if (reason !== "backdropClick") {
                setOpen(false);
                if (onDismiss) onDismiss();
            }
        },
        [onDismiss],
    );

    const handleOnProceed = useCallback(() => {
        setOpen(false);
        if (onConfirm) onConfirm();
    }, [onConfirm]);

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogTitle>
                <Stack
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{
                        width: "100%",
                    }}
                >
                    <Typography
                        color={theme.palette.primary.textTitleColor}
                        variant="bodyLarge"
                    >
                        {dialogTitleText}
                    </Typography>
                    <FontAwesomeIcon
                        icon={faXmark}
                        onClick={handleDialogClose}
                        style={{ cursor: "pointer" }}
                    />
                </Stack>
            </DialogTitle>
            <Divider variant="fullWidth" />
            <DialogContent>
                <Stack flexDirection="row" gap={2}>
                    <Stack flexDirection="column" gap={1}>
                        <Typography>{dialogContentText}</Typography>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleDialogClose}>
                    {dialogSecondaryButtonText}
                </Button>
                <Button variant="contained" onClick={handleOnProceed}>
                    {dialogPrimaryButtonText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
