import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Chip, Stack, useTheme } from "@vapor/react-material";
import { ReactElement } from "react";
import { FulfilmentFilterDto } from "../../../../../core/dtos/FulfilmentFilterDto";
import { TaxPayerActivityCountersDto } from "../../../../../core/dtos/TaxPayerActivityCountersDto";
import {
    ActivityStatus,
    getLabelFromStatus,
} from "../../../../components/activity-counter/ActivityCounter";

const baseFilter: FulfilmentFilterDto = {
    completed: false,
    closed: false,
    pending: false,
    error: false,
};

interface FulfimentActivityFilterProps {
    filters: FulfilmentFilterDto;
    hideTotalActivityCounter?: boolean;
    onClickFilter: (filter: FulfilmentFilterDto) => unknown;
    taxPayerActivityCounters: TaxPayerActivityCountersDto;
}

export const FulfimentActivityFilter = ({
    filters,
    hideTotalActivityCounter = false,
    onClickFilter,
    taxPayerActivityCounters: activityCounters,
}: FulfimentActivityFilterProps): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    return (
        <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
            <Chip
                variant={
                    !filters.closed &&
                    !filters.completed &&
                    !filters.error &&
                    !filters.pending
                        ? "outlined"
                        : "filled"
                }
                label={
                    <Typography>
                        {t(
                            "views.home.components.fulfilment_activity_filter.all_filters",
                            { defaultValue: "Tutti" },
                        )}
                    </Typography>
                }
                onClick={() => onClickFilter({ ...baseFilter })}
            />
            <Chip
                variant={filters.pending ? "outlined" : "filled"}
                label={getLabelFromStatus(
                    ActivityStatus.PENDING,
                    t,
                    activityCounters.pending,
                )}
                onClick={() => {
                    if (!filters.pending) {
                        onClickFilter({
                            ...baseFilter,
                            pending: true,
                        });
                    }
                }}
            />
            <Chip
                variant={filters.closed ? "outlined" : "filled"}
                label={getLabelFromStatus(
                    ActivityStatus.CLOSED,
                    t,
                    activityCounters.closed,
                )}
                onClick={() => {
                    if (!filters.closed) {
                        onClickFilter({
                            ...baseFilter,
                            closed: true,
                        });
                    }
                }}
            />
            <Chip
                variant={filters.completed ? "outlined" : "filled"}
                label={getLabelFromStatus(
                    ActivityStatus.COMPLETED,
                    t,
                    activityCounters.completed,
                )}
                onClick={() => {
                    if (!filters.completed) {
                        onClickFilter({
                            ...baseFilter,
                            completed: true,
                        });
                    }
                }}
            />
            <Chip
                variant={filters.error ? "outlined" : "filled"}
                label={getLabelFromStatus(
                    ActivityStatus.ERROR,
                    t,
                    activityCounters.error,
                )}
                onClick={() => {
                    if (!filters.error) {
                        onClickFilter({
                            ...baseFilter,
                            error: true,
                        });
                    }
                }}
            />
            {!hideTotalActivityCounter && (
                <Typography
                    color={theme.palette.primary.textSubduedColor}
                    pl={2}
                    variant="label"
                >
                    {`${t(
                        "views.home.components.fulfilment_activity_filter.taxPayers_number",
                        { defaultValue: "Totale clienti:" },
                    )} ${activityCounters.total}`}
                </Typography>
            )}
        </Stack>
    );
};
