import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { FulfilmentActivityEventType } from "../../dtos/FulfilmentActivityEventType";
import {
    FulfilmentDetailDto,
    FulfilmentStatus,
} from "../../dtos/FulfilmentDetailDto";
import { usePostWithErrorHandler } from "../../hooks/usePostWithErrorHandler";

interface UseCloseFulfilmentInput {
    taxPayerWorkspaceId: string;
    fulfilment?: FulfilmentDetailDto;
    levyId: string;
    year: number;
}

export function useCloseFulfilment({
    taxPayerWorkspaceId,
    fulfilment,
    levyId,
    year,
}: UseCloseFulfilmentInput): {
    closeFulfilment: () => Promise<boolean>;
    loading: boolean;
    isFulfilmentCloseable: () => boolean;
} {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading } = usePostWithErrorHandler<boolean>(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${levyId}/years/${year}/tax-payers/${taxPayerWorkspaceId}/activities`,
        {
            description: "Chiusura manuale",
            type: FulfilmentActivityEventType.LevyClosedV1,
        },
        {
            ...useGetAxiosRequestConfigParams("use-close-fulfilment"),
        },
    );

    return {
        closeFulfilment: async () => {
            try {
                return !!(await fetch());
            } catch (e) {
                return false;
            }
        },
        isFulfilmentCloseable: () =>
            fulfilment?.status === FulfilmentStatus.PENDING,
        loading: loading,
    };
}
