import { IS_DEV_ENV } from "../config";

const useApplicationToken = () => {
    const firstName = "Tony";
    const lastName = "Stark";
    const email = "tony.stark@avengers.it";
    const jwt =
        "ewogICJhbGciOiAiUlM1MTIiLAogICJ0eXAiOiAibm9uZSIKfQ.ewogICJpc3MiOiAiaHR0cHM6Ly9pZGVudGl0eS1zdGFnZS50ZWFtc3lzdGVtLmNvbSIsCiAgIm5iZiI6IDE2OTU5OTg3MDAsCiAgImlhdCI6IDE2OTU5OTg3MDAsCiAgImV4cCI6IDQwOTQzNjkxMDAsCiAgInNjb3BlIjogWwogICAgIm9wZW5pZCIsCiAgICAib2ZmbGluZV9hY2Nlc3MiCiAgXSwKICAiYW1yIjogWwogICAgInB3ZCIKICBdLAogICJjbGllbnRfaWQiOiAiMzk0MDJlYTctNDk4MS00NzgwLWE0NDgtNGJmMWM3ZWI0NmNiIiwKICAic3ViIjogIjM5NDAyZWE3LTQ5ODEtNDc4MC1hNDQ4LTRiZjFjN2ViNDZjYmYiLAogICJhdXRoX3RpbWUiOiAxNjk1ODI1NzI1LAogICJpZHAiOiAibG9jYWwiLAogICJuYW1lIjogIlRvbnkgU3RhcmsiLAogICJnaXZlbl9uYW1lIjogIlRvbnkiLAogICJmYW1pbHlfbmFtZSI6ICJTdGFyayIsCiAgImVtYWlsIjogInRvbnkuc3RhcmtAYXZlbmdlcnMuaXQiLAogICJuY3NfaWQiOiAiMzk0MDJlYTctNDk4MS00NzgwLWE0NDgtNGJmMWM3ZWI0NmNiIiwKICAic2lkIjogIjM5NDAyZWE3LTQ5ODEtNDc4MC1hNDQ4LTRiZjFjN2ViNDZjYiIKfQ.";

    return {
        set: async () => {},
        get: async () => {
            return { applicationToken: jwt };
        },
        introspect: async (token: string) => ({
            token,
            tokenData: {
                family_name: lastName,
                given_name: firstName,
                email: email,
                sub: email,
            },
        }),
        verify: async (token: string) => ({
            token,
            tokenData: {
                family_name: lastName,
                given_name: firstName,
                email: email,
                sub: email,
            },
        }),
        refresh: async (token: string) => ({
            token: jwt,
            tokenData: {
                family_name: lastName,
                given_name: firstName,
                email: email,
                sub: email,
            },
        }),
    };
};

export const authService = () =>
    IS_DEV_ENV
        ? {
              target: "$ONE_AUTH_USE_APPLICATION_TOKEN",
              handler: {
                  hook: useApplicationToken,
              },
          }
        : undefined;
