import { faArrowDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, Button, Stack, useTheme } from "@vapor/react-material";
import dayjs from "dayjs";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { AssociableFulfilmentDto } from "../../../../../core/dtos/AssociableFulfilmentDto";
import { AssociableFulfilmentsDto } from "../../../../../core/dtos/AssociableFulfilmentsDto";
import {
    FulfilmentListProps,
    groupFulfilmentsByDeadline,
} from "../fulfilment-list/FulfilmentList";
import { FulfilmentNonAssociated } from "../fulfilment-non-associated/FulfilmentNonAssociated";

const ITEMS_PER_PAGE = 5;

interface FulfilmentListNotAssociatedProps
    extends FulfilmentListProps<AssociableFulfilmentsDto> {}

export const FulfilmentListNotAssociated = ({
    fulfilments,
    onClickAssociateTaxPayersToFulfilment,
}: FulfilmentListNotAssociatedProps): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    const groupedFulfilmentsByDeadline = useMemo(
        () =>
            groupFulfilmentsByDeadline<
                AssociableFulfilmentsDto,
                AssociableFulfilmentDto[]
            >(fulfilments),
        [fulfilments],
    );
    const [displayedFulfilments, setDisplayedFulfilments] = useState(
        groupedFulfilmentsByDeadline.slice(0, ITEMS_PER_PAGE),
    );

    const [page, setPage] = useState(0);
    const maxPage = useMemo(
        () => Math.ceil(groupedFulfilmentsByDeadline.length / ITEMS_PER_PAGE),
        [groupedFulfilmentsByDeadline],
    );

    const handleNext = () => {
        setPage((old) => Math.min(old + 1, maxPage));
    };

    useEffect(() => {
        const newFulfilments = groupedFulfilmentsByDeadline.slice(
            0,
            (page + 1) * ITEMS_PER_PAGE,
        );
        setDisplayedFulfilments([...newFulfilments]);
    }, [groupedFulfilmentsByDeadline, page]);

    return (
        <Stack>
            {displayedFulfilments.map((group) => (
                <Box
                    key={group.deadline.toISOString()}
                    sx={{ marginBottom: 2 }}
                >
                    {group.fulfilments.map((fulfilment, index) => (
                        <Box key={index}>
                            <FulfilmentNonAssociated
                                date={dayjs(fulfilment.deadline)}
                                description={fulfilment.description}
                                isCustomFulfilment={false}
                                key={fulfilment.levyId}
                                levyId={fulfilment.levyId}
                                onClickAssociateTaxPayers={(_) => {
                                    onClickAssociateTaxPayersToFulfilment(
                                        fulfilment,
                                    );
                                }}
                                title={fulfilment.title}
                                header={index === 0}
                            />
                        </Box>
                    ))}
                </Box>
            ))}
            {page < maxPage && (
                <Button
                    disabled={
                        page ===
                        groupedFulfilmentsByDeadline.length / ITEMS_PER_PAGE
                    }
                    onClick={handleNext}
                    sx={{ placeSelf: "center" }}
                    variant="text"
                >
                    <FontAwesomeIcon
                        icon={faArrowDown}
                        color={theme.palette.primary.interactiveDefault}
                    />
                    <Typography
                        color={theme.palette.primary.interactiveDefault}
                        ml={1}
                    >
                        {t(
                            "views.home.components.fulfilment_list_not_associated.load_more",
                            "Carica altri",
                        )}
                    </Typography>
                </Button>
            )}
        </Stack>
    );
};
