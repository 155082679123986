import { useCurrentTenant } from "@drift/oneplatfront";
import { useCallback } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { FulfilmentStatus } from "../../dtos/FulfilmentDetailDto";
import { FulfilmentsDto } from "../../dtos/FulfilmentsDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

export interface FulfilmentFilterSearch {
    deadlineFrom: Date;
    deadlineTo: Date;
    fulfilmentName?: string;
    statuses: FulfilmentStatus[];
    taxPayerWorkspaceId?: string;
    withErrors: boolean;
    withWarnings: boolean;
}

export function useGetFulfilments(): BaseUseCaseResponseDto<
    FulfilmentsDto,
    FulfilmentFilterSearch
> {
    const { tenant } = useCurrentTenant(true);

    const { error, fetch, loading, data } = useGetWithErrorHandler<
        FulfilmentsDto,
        FulfilmentFilterSearch
    >(`rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments`, {
        ...useGetAxiosRequestConfigParams("use-get-fulfilments"),
    });

    const fetchData = useCallback(
        async (args?: { params?: FulfilmentFilterSearch }) => {
            const fulfilmentName = args?.params?.fulfilmentName
                ?.trim()
                .replaceAll(/\s{2,}/g, " ");

            return await fetch({
                params: {
                    deadlineFrom:
                        (args?.params?.deadlineFrom?.getTime() as any) || 0,
                    deadlineTo:
                        (args?.params?.deadlineTo?.getTime() as any) || 0,
                    statuses: args?.params?.statuses || [],
                    ...(!!args?.params?.taxPayerWorkspaceId
                        ? {
                              taxPayerWorkspaceId:
                                  args?.params?.taxPayerWorkspaceId,
                          }
                        : {}),
                    withErrors: args?.params?.withErrors || false,
                    withWarnings: args?.params?.withWarnings || false,
                    ...(!!fulfilmentName ? { fulfilmentName } : {}),
                },
            });
        },
        [fetch],
    );

    return {
        data: data,
        error: error,
        fetch: fetchData,
        loading: loading,
    };
}
