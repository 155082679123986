import { useCurrentTenant } from "@drift/oneplatfront";
import { useCallback } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { AssociableFulfilmentsDto } from "../../dtos/AssociableFulfilmentsDto";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

export interface AssociableFulfilmentFilterSearch {
    deadlineFrom: Date;
    deadlineTo: Date;
    fulfilmentName?: string;
}

export function useGetAssociableFulfilments(): BaseUseCaseResponseDto<
    AssociableFulfilmentsDto,
    AssociableFulfilmentFilterSearch & { custom: boolean }
> {
    const { tenant } = useCurrentTenant(true);

    const { error, fetch, loading, data } = useGetWithErrorHandler<
        AssociableFulfilmentsDto,
        AssociableFulfilmentFilterSearch & { custom: boolean }
    >(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/associable-fulfilments`,
        {
            ...useGetAxiosRequestConfigParams("use-get-associable-fulfilments"),
        },
    );

    const fetchData = useCallback(
        async (args?: {
            params?: AssociableFulfilmentFilterSearch & { custom: boolean };
        }) => {
            const fulfilmentName = args?.params?.fulfilmentName
                ?.trim()
                .replaceAll(/\s{2,}/g, " ");

            return await fetch({
                params: {
                    custom: args?.params?.custom || false,
                    deadlineFrom:
                        (args?.params?.deadlineFrom?.getTime() as any) || 0,
                    deadlineTo:
                        (args?.params?.deadlineTo?.getTime() as any) || 0,
                    ...(!!fulfilmentName ? { fulfilmentName } : {}),
                },
            });
        },
        [fetch],
    );

    return {
        data: data,
        error: error,
        fetch: fetchData,
        loading: loading,
    };
}
