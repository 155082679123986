import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { FulfilmentDetailDto } from "../../dtos/FulfilmentDetailDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface UseGetFulfilmentDetailInput {
    taxPayerWorkspaceId: string;
    levyId: string;
    year: number;
}

export function useGetFulfilmentDetail({
    levyId,
    year,
    taxPayerWorkspaceId,
}: UseGetFulfilmentDetailInput): BaseUseCaseResponseDto<FulfilmentDetailDto> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } =
        useGetWithErrorHandler<FulfilmentDetailDto>(
            `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${levyId}/years/${year}/tax-payers/${taxPayerWorkspaceId}`,
            {
                ...useGetAxiosRequestConfigParams("use-get-fulfilment-detail"),
            },
        );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
