import {
    faClock,
    faEllipsisVertical,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Button,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    useTheme,
} from "@vapor/react-material";
import { Dayjs } from "dayjs";
import { capitalize } from "lodash";
import { ReactElement, useEffect, useState } from "react";
import { v4 } from "uuid";
import { formatDate } from "../../../../../utils/dateUtils";
import { DeleteCustomFulfilment } from "../../../../components/delete-custom-fulfilment/DeleteCustomFulfilment";

interface FulfilmentNonAssociatedProps {
    date: Dayjs;
    description: string;
    isCustomFulfilment: boolean;
    levyId: string;
    onClickAssociateTaxPayers: (levyId?: string) => unknown;
    onDeleteCustomFulfilment?: () => unknown;
    title: string;
    header?: boolean;
}

export const FulfilmentNonAssociated = ({
    date,
    description,
    isCustomFulfilment,
    levyId,
    onClickAssociateTaxPayers,
    onDeleteCustomFulfilment,
    title,
    header = false,
}: FulfilmentNonAssociatedProps): ReactElement => {
    // states
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    const onClickMenuItemAssociateTaxPayers = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.stopPropagation();
        onClickAssociateTaxPayers(levyId);
    };

    const [fulfilmentTitleMaxWidth, setFulfilmentTitleMaxWidth] =
        useState("0px");
    const [fulfilmentAssociateButtonId] = useState(v4());
    const [fulfilmentContainerId] = useState(v4());

    const [menuAnchorEl, setMenuAnchorEl] = useState<
        (EventTarget & HTMLButtonElement) | null
    >(null);

    const onClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(null);
    };

    const onClickMenuItemDeleteCustomFulfilment = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.stopPropagation();
    };

    const _onDeleteCustomFulfilment = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        handleCloseMenu(event);
        onDeleteCustomFulfilment && onDeleteCustomFulfilment();
    };

    //TODO: refactor
    useEffect(() => {
        const handleResize = () => {
            const fulfilmentOffsetLeft =
                (document
                    .getElementById(fulfilmentContainerId)
                    ?.getBoundingClientRect().left || 0) * 2;
            const fulfilmentAssociateButtonWidth =
                document.getElementById(fulfilmentAssociateButtonId)
                    ?.clientWidth || 0;

            const widthVar = `calc(98vw - ${fulfilmentOffsetLeft}px - ${fulfilmentAssociateButtonWidth}px)`;

            setFulfilmentTitleMaxWidth(widthVar);
        };
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [fulfilmentContainerId, fulfilmentAssociateButtonId]);

    return (
        <Stack
            direction="column"
            id={fulfilmentContainerId}
            sx={{
                border: "1px solid #B9C8D0",
                borderTop: "none",
            }}
        >
            {header && (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                        background: theme.palette.primary.background,
                        borderTop: "1px solid #B9C8D0",
                        paddingX: 2,
                        paddingY: 1,
                    }}
                >
                    <FontAwesomeIcon
                        icon={faClock}
                        color={theme.palette.primary.textTitleColor}
                    />
                    <Typography
                        variant="subtitle2"
                        color={theme.palette.primary.textTitleColor}
                        sx={{ lineHeight: 1 }}
                    >
                        {t("views.home.components.fulfilment.header.deadline", {
                            defaultValue: "Scadenza:",
                        })}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color={theme.palette.primary.textTitleColor}
                        sx={{ lineHeight: 1 }}
                    >
                        {formatDate(date)}
                    </Typography>
                </Stack>
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ padding: 1.5 }}
            >
                <Stack maxWidth={fulfilmentTitleMaxWidth} sx={{ width: 1 }}>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        minHeight={32}
                        mr={2}
                    >
                        <Stack
                            sx={{
                                cursor: "pointer",
                                display: "none",
                                width: 1,
                                ":hover": {
                                    width: "100%",
                                    color: "rgb(0, 144, 209)",
                                    display: "inline",
                                },
                                zIndex: "2",
                            }}
                            style={{
                                backgroundColor:
                                    theme.palette.primary.whiteSmoke,
                            }}
                        >
                            <Tooltip title={description}>
                                <Typography
                                    variant="titleSmall"
                                    color={theme.palette.text.primary}
                                    noWrap
                                >
                                    {description}
                                </Typography>
                            </Tooltip>
                        </Stack>
                        <Typography
                            variant="titleSmall"
                            color={theme.palette.text.primary}
                            noWrap
                        >
                            {description}
                        </Typography>
                    </Stack>
                    <Box
                        sx={{
                            background: theme.palette.primary.background,
                            width: "fit-content",
                            padding: 1,
                            borderRadius: "4px",
                        }}
                    >
                        <Typography
                            color={theme.palette.primary.textSubduedColor}
                            variant="subtitle2"
                            sx={{
                                lineHeight: 1,
                            }}
                        >
                            {capitalize(title)}
                        </Typography>
                    </Box>
                </Stack>
                <Stack justifyContent="center" id={fulfilmentAssociateButtonId}>
                    <Button
                        variant="outlined"
                        onClick={onClickMenuItemAssociateTaxPayers}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                        <Typography ml={1}>
                            {t(
                                "views.home.components.fulfilment_non_associated.actions.associate",
                                "Associa",
                            )}
                        </Typography>
                    </Button>
                </Stack>
                {isCustomFulfilment && (
                    <Stack direction="row" alignItems="center">
                        <IconButton
                            aria-controls="menu"
                            aria-haspopup="true"
                            onClick={onClickMenu}
                            size="small"
                        >
                            <FontAwesomeIcon
                                color={theme.palette.primary.interactiveDefault}
                                icon={faEllipsisVertical}
                                size="xs"
                            />
                        </IconButton>
                        <Menu
                            id="menu"
                            anchorEl={menuAnchorEl}
                            open={!!menuAnchorEl}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem
                                onClick={onClickMenuItemDeleteCustomFulfilment}
                            >
                                <DeleteCustomFulfilment
                                    levyId={levyId}
                                    onCancel={handleCloseMenu}
                                    onDelete={(event) =>
                                        _onDeleteCustomFulfilment(event)
                                    }
                                    sx={{ width: "100%" }}
                                >
                                    {t(
                                        "views.home.components.fulfilment_non_associated.menu_items.delete",
                                        {
                                            defaultValue: "Elimina",
                                        },
                                    )}
                                </DeleteCustomFulfilment>
                            </MenuItem>
                        </Menu>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
