import {
    AdapterDateFns,
    LocalizationProvider,
} from "@vapor/react-x-date-pickers";
import it from "date-fns/locale/it";

export const localizationService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={it}
            >
                {children}
            </LocalizationProvider>
        ),
    },
});
