import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { faAngleLeft, faAngleUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Theme } from "@mui/system";
import { useTranslation } from "@onefront/react-sdk";
import {
    DateRange,
    LoadingWrapper,
    VaporPageStickable,
} from "@vapor/react-contrib-tax-compliance";
import { VaporPageStickableDrawerProps } from "@vapor/react-contrib-tax-compliance/VaporPageStickable";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Stack,
    useMediaQuery,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { REACT_APP_ENV, APP_VERSION } from "../../../config";
import { AssociableFulfilmentDto } from "../../../core/dtos/AssociableFulfilmentDto";
import { FulfilmentDto } from "../../../core/dtos/FulfilmentDto";
import { useWarningNotification } from "../../../core/hooks/useWarningNotification";
import { useGetAssociableFulfilments } from "../../../core/usecases/use-get-associable-fulfilments/useGetAssociableFulfilments";
import { useGetAssociableTaxPayers } from "../../../core/usecases/use-get-associable-tax-payers/useGetAssociableTaxPayers";
import { useGetFulfilmentTaxPayers } from "../../../core/usecases/use-get-fulfilment-tax-payers/useGetFulfilmentTaxPayers";
import {
    FulfilmentFilterSearch,
    useGetFulfilments,
} from "../../../core/usecases/use-get-fulfilments/useGetFulfilments";
import { mergeFulfilments } from "../../../utils/fulfilmentUtils";
import { AddCustomFulfilment } from "./components/add-custom-fulfilment/AddCustomFulfilment";
import { FulfilmentAssociation } from "./components/fulfilment-association/FulfilmentAssociation";
import { FulfilmentListHeader } from "./components/fulfilment-list-header/FulfilmentListHeader";
import { FulfilmentListNotAssociated } from "./components/fulfilment-list-not-associated/FulfilmentListNotAssociated";
import { FulfilmentList } from "./components/fulfilment-list/FulfilmentList";
import { FulfilmentTaxPayerList } from "./components/fulfilment-tax-payer-list/FulfilmentTaxPayerList";

interface FulfilmentAssociationAction {
    type: "ASSOCIATE" | "SET" | "UNSET";
    payload?: AssociableFulfilmentDto;
}

interface FulfilmentAssociationState {
    fulfilment?: AssociableFulfilmentDto;
    associated: boolean;
}

function fulfilmentAssociationReducer(
    state: FulfilmentAssociationState,
    action: FulfilmentAssociationAction,
) {
    switch (action.type) {
        case "ASSOCIATE":
            return { fulfilment: state.fulfilment, associated: true };
        case "SET":
            return { fulfilment: action.payload, associated: false };
        case "UNSET":
            return { fulfilment: undefined, associated: false };
    }
}

export const Home = () => {
    // states
    const { showTextMessage: showWarningMessage } = useWarningNotification();
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");
    const isXlScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(theme.breakpoints.values.xl),
    );
    const devOrTestEnv =
        REACT_APP_ENV === "development" || REACT_APP_ENV === "test";

    // states->drawer
    const [drawerContent, setDrawerContent] =
        useState<VaporPageStickableDrawerProps>();

    // states -> expiring filter
    const [expiringFilterEnabled, setExpiringFilterEnabled] = useState(false);

    // states->fulfilments
    const [fulfilmentFilterSearch, setFulfilmentFilterSearch] =
        useState<FulfilmentFilterSearch>({
            deadlineFrom: dayjs().subtract(1, "month").startOf("day").toDate(),
            deadlineTo: dayjs().add(1, "month").endOf("day").toDate(),
            statuses: [],
            withErrors: false,
            withWarnings: false,
            fulfilmentName: "",
        });

    // states->fulfilment to associate
    const [fulfilmentAssociation, dispatchFulfilmentAssociation] = useReducer(
        fulfilmentAssociationReducer,
        {
            fulfilment: undefined,
            associated: false,
        },
    );

    // states -> header right width used to create a fake header left spacer in order to center title and subtitle
    const headerRightRef = useRef(null);
    const [headerRightWidth, setHeaderRightWidth] = useState(0);

    // states->non associated fulfilments accordion
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

    const [previousFulfilmentFilterSearch, setPreviousFulfilmentFilterSearch] =
        useState<FulfilmentFilterSearch>(fulfilmentFilterSearch);

    // states->selected fulfilment
    const [selectedFulfilment, setSelectedFulfilment] =
        useState<FulfilmentDto>();

    // use cases
    const {
        fetch: getFulfilmentsFetch,
        loading: getFulfilmentsLoading,
        data: getFulfilmentsData,
    } = useGetFulfilments();

    const {
        fetch: getAssociableFulfilmentsFetch,
        loading: getAssociableFulfilmentsLoading,
        data: getAssociableFulfilmentsData,
    } = useGetAssociableFulfilments();

    const {
        fetch: getCustomAssociableFulfilmentsFetch,
        loading: getCustomAssociableFulfilmentsLoading,
        data: getCustomAssociableFulfilmentsData,
    } = useGetAssociableFulfilments();

    const {
        fetch: getFulfilmentTaxPayersFetch,
        loading: getFulfilmentTaxPayersLoading,
        data: getFulfilmentTaxPayersData,
    } = useGetFulfilmentTaxPayers({
        levyId: selectedFulfilment?.levyId || "",
        year: dayjs(selectedFulfilment?.deadline).year(),
    });

    const {
        fetch: fetchAssociableTaxPayers,
        loading: isLoadingAssociableTaxPayers,
        data: associableTaxPayers,
    } = useGetAssociableTaxPayers();

    const fetchFulfilments = useCallback(() => {
        getFulfilmentsFetch({
            params: { ...fulfilmentFilterSearch },
        });
    }, [fulfilmentFilterSearch, getFulfilmentsFetch]);

    const fetchAssociableFulfilments = useCallback(() => {
        getAssociableFulfilmentsFetch({
            params: { ...fulfilmentFilterSearch, custom: false },
        });
    }, [fulfilmentFilterSearch, getAssociableFulfilmentsFetch]);

    const fetchCustomAssociableFulfilments = useCallback(() => {
        getCustomAssociableFulfilmentsFetch({
            params: { ...fulfilmentFilterSearch, custom: true },
        });
    }, [fulfilmentFilterSearch, getCustomAssociableFulfilmentsFetch]);

    // side effects
    const debouncedGetFulfilments = useDebouncedCallback((cb: () => void) => {
        cb();
    }, 500);

    useEffect(() => {
        debouncedGetFulfilments(() => {
            fetchFulfilments();
            fetchAssociableFulfilments();
            fetchCustomAssociableFulfilments();
        });
    }, [
        debouncedGetFulfilments,
        fetchFulfilments,
        fetchAssociableFulfilments,
        fetchCustomAssociableFulfilments,
    ]);

    useEffect(() => {
        if (selectedFulfilment) {
            getFulfilmentTaxPayersFetch();
        }
    }, [getFulfilmentTaxPayersFetch, selectedFulfilment]);

    useEffect(() => {
        if (selectedFulfilment) {
            setDrawerContent({
                sxContent: { padding: 0 },
                content: (
                    <FulfilmentTaxPayerList
                        isLoading={getFulfilmentTaxPayersLoading}
                        selectedFulfilment={selectedFulfilment}
                        taxPayers={getFulfilmentTaxPayersData || []}
                        taxPayerActivityCounters={
                            selectedFulfilment.taxPayerCounters
                        }
                    />
                ),
                headerDescription: selectedFulfilment.title,
                headerTitle: selectedFulfilment.description,
                onClickCloseButton: () => setSelectedFulfilment(undefined),
                open: !!selectedFulfilment,
                width: isXlScreen ? "40%" : "60%",
            });
        }
        return () => {
            setDrawerContent(undefined);
        };
    }, [
        getFulfilmentTaxPayersData,
        getFulfilmentTaxPayersLoading,
        isXlScreen,
        selectedFulfilment,
    ]);

    useEffect(() => {
        if (fulfilmentAssociation.fulfilment) {
            fetchAssociableTaxPayers({
                params: {
                    levyId: fulfilmentAssociation.fulfilment.levyId,
                    year: dayjs(
                        fulfilmentAssociation.fulfilment.deadline,
                    ).year(),
                },
            });
        }
    }, [fetchAssociableTaxPayers, fulfilmentAssociation.fulfilment]);

    useEffect(() => {
        if (fulfilmentAssociation.fulfilment) {
            setDrawerContent({
                sxContent: { padding: 0 },
                content: (
                    <FulfilmentAssociation
                        onAssociateTaxPayer={() => {
                            dispatchFulfilmentAssociation({
                                type: "ASSOCIATE",
                            });
                        }}
                        isLoading={isLoadingAssociableTaxPayers}
                        levyId={fulfilmentAssociation.fulfilment.levyId}
                        taxPayers={associableTaxPayers || []}
                        year={dayjs(
                            fulfilmentAssociation.fulfilment.deadline,
                        ).year()}
                    />
                ),
                headerDescription: t(
                    "views.home.drawer.fulfilment_association.header.title",
                    { defaultValue: "Associa" },
                ),
                headerTitle: fulfilmentAssociation.fulfilment.description,
                onClickCloseButton: () => {
                    if (fulfilmentAssociation.associated) {
                        fetchFulfilments();
                        fetchAssociableFulfilments();
                        fetchCustomAssociableFulfilments();
                    }
                    dispatchFulfilmentAssociation({ type: "UNSET" });
                },
                open: !!fulfilmentAssociation.fulfilment,
                width: isXlScreen ? "40%" : "60%",
            });
        }
        return () => {
            setDrawerContent(undefined);
        };
    }, [
        associableTaxPayers,
        fetchAssociableFulfilments,
        fetchCustomAssociableFulfilments,
        fetchFulfilments,
        fulfilmentAssociation.associated,
        fulfilmentAssociation.fulfilment,
        isLoadingAssociableTaxPayers,
        isXlScreen,
        t,
    ]);

    useEffect(() => {
        if (expiringFilterEnabled) {
            setFulfilmentFilterSearch((fulfilmentFilterSearch) => {
                setPreviousFulfilmentFilterSearch(fulfilmentFilterSearch);

                return {
                    ...fulfilmentFilterSearch,
                    deadlineFrom: dayjs().startOf("day").toDate(),
                    deadlineTo: dayjs().add(14, "day").endOf("day").toDate(),
                    statuses: [],
                };
            });
        }
    }, [expiringFilterEnabled]);

    useEffect(() => {
        if (!expiringFilterEnabled) {
            setFulfilmentFilterSearch(previousFulfilmentFilterSearch);
        }
    }, [expiringFilterEnabled, previousFulfilmentFilterSearch]);

    useEffect(() => {
        const updateWidth = () => {
            if (headerRightRef.current) {
                setHeaderRightWidth(
                    (headerRightRef.current as any).offsetWidth,
                );
            }
        };

        updateWidth();
        window.addEventListener("resize", updateWidth);

        return () => {
            window.removeEventListener("resize", updateWidth);
        };
    }, []);

    //functions
    const onClickExpiringChip = () => {
        setExpiringFilterEnabled((prevValue) => !prevValue);
    };

    const handleDateRangeValidation = () => {
        showWarningMessage(
            t("views.home.date_range.warning", {
                defaultValue: "Puoi selezionare un massimo di 365 giorni",
            }),
        );
    };

    const handleOnClickAddCustomFulfilment = () => {
        setDrawerContent({
            content: (
                <AddCustomFulfilment
                    onAddCustomFulfilment={() => {
                        fetchFulfilments();
                        fetchCustomAssociableFulfilments();
                        setDrawerContent(undefined);
                    }}
                    onClickCancel={() => setDrawerContent(undefined)}
                />
            ),
            headerTitle: t(
                "views.home.drawer.add_custom_fulfilment.header.title",
                {
                    defaultValue: "Nuovo adempimento personalizzato",
                },
            ),
            onClickCloseButton: () => setDrawerContent(undefined),
            open: true,
            sxContent: { padding: 0 },
            width: isXlScreen ? "30%" : "40%",
        });
    };

    const resolveSubtitle = () => {
        let subtitle: string = t("views.home.header.subtitle", {
            defaultValue: "Panoramica",
        });
        if (devOrTestEnv) {
            subtitle += `${"\n"}${REACT_APP_ENV} ${APP_VERSION}`;
        }
        return subtitle;
    };

    return (
        <VaporPageStickable
            drawer={drawerContent}
            headerBottom={
                <Stack
                    alignContent="baseline"
                    direction="row"
                    justifyContent="space-between"
                    padding={2}
                    spacing={10}
                >
                    <Box flexGrow={2}>
                        <FulfilmentListHeader
                            filters={fulfilmentFilterSearch}
                            onClickExpiringChip={onClickExpiringChip}
                            onClickAddCustomFulfilment={
                                handleOnClickAddCustomFulfilment
                            }
                            onFilterChange={setFulfilmentFilterSearch}
                            taxPayers={getFulfilmentsData?.taxPayers || []}
                        />
                    </Box>
                </Stack>
            }
            headerBottomDivider
            headerLeft={
                // fake spacer to center title and subtitle
                <Box visibility="hidden" width={headerRightWidth}></Box>
            }
            headerRight={
                <Stack
                    alignItems="center"
                    direction="row"
                    id="header-right"
                    spacing={2}
                    mr={2}
                    ref={headerRightRef}
                >
                    <DateRange
                        currentMonthBtnLabel={t(
                            "views.home.date_range.current_month",
                            { defaultValue: "Mese corrente" },
                        )}
                        disableValidationAlert
                        endDate={fulfilmentFilterSearch.deadlineTo}
                        handleCustomValidation={handleDateRangeValidation}
                        onChangeDates={(startDate, endDate) => {
                            setFulfilmentFilterSearch((prevValue) => {
                                return {
                                    ...prevValue,
                                    deadlineFrom: startDate,
                                    deadlineTo: endDate,
                                };
                            });
                        }}
                        showCurrentMonthBtn
                        startDate={fulfilmentFilterSearch.deadlineFrom}
                    />
                </Stack>
            }
            stickyHeader
            sxContent={{ padding: 2 }}
            subtitle={resolveSubtitle()}
            sxHeader={{ whiteSpace: "pre-line" }}
            title={t("views.home.header.title", {
                defaultValue: "Adempimenti",
            })}
        >
            <Stack direction="column" spacing={2}>
                <LoadingWrapper
                    loading={
                        getAssociableFulfilmentsLoading ||
                        getFulfilmentsLoading ||
                        getCustomAssociableFulfilmentsLoading
                    }
                    variant="skeleton-list"
                >
                    {getFulfilmentsData &&
                        getCustomAssociableFulfilmentsData && (
                            <FulfilmentList
                                fulfilments={{
                                    fulfilments: mergeFulfilments(
                                        getFulfilmentsData.fulfilments,
                                        getCustomAssociableFulfilmentsData.fulfilments,
                                    ),
                                    taxPayers: getFulfilmentsData.taxPayers,
                                }}
                                onClickFulfilment={(
                                    fulfilment: FulfilmentDto,
                                ) => {
                                    setSelectedFulfilment(fulfilment);
                                }}
                                onClickAssociateTaxPayersToFulfilment={(
                                    fulfilment,
                                ) =>
                                    dispatchFulfilmentAssociation({
                                        type: "SET",
                                        payload: fulfilment,
                                    })
                                }
                                onDeleteCustomFulfilment={() => {
                                    fetchFulfilments();
                                    fetchCustomAssociableFulfilments();
                                }}
                            />
                        )}
                    {getAssociableFulfilmentsData && (
                        <Accordion
                            disableGutters
                            expanded={isAccordionExpanded}
                            onChange={(_, newExpanded) =>
                                setIsAccordionExpanded(newExpanded)
                            }
                            sx={{
                                boxShadow: "none",
                                "&::before": {
                                    display: "none",
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <FontAwesomeIcon
                                        icon={
                                            isAccordionExpanded
                                                ? faAngleUp
                                                : faAngleLeft
                                        }
                                    />
                                }
                                sx={{ marginBottom: 2 }}
                            >
                                <Typography
                                    variant="body700"
                                    color={
                                        theme.palette.primary.interactiveDefault
                                    }
                                >
                                    {t(
                                        "views.home.accordion.non_associated_fulfilments.label",
                                        {
                                            defaultValue:
                                                "Tutti gli adempimenti in scadenza nel mese corrente",
                                        },
                                    )}
                                </Typography>
                                <Stack direction="row" spacing={2}>
                                    <Typography variant="body">
                                        {t(
                                            "views.home.accordion.non_associated_fulfilments.action",
                                            {
                                                defaultValue:
                                                    "Adempimenti non ancora associati ai tuoi contribuenti. Associa manualmente",
                                            },
                                        )}
                                    </Typography>
                                    <FontAwesomeIcon
                                        color={
                                            theme.palette.primary
                                                .interactiveDefault
                                        }
                                        icon={faTriangleExclamation}
                                    />
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails
                                sx={{
                                    "&.MuiAccordionDetails-root": {
                                        padding: 0,
                                    },
                                }}
                            >
                                <FulfilmentListNotAssociated
                                    fulfilments={getAssociableFulfilmentsData}
                                    onClickAssociateTaxPayersToFulfilment={(
                                        fulfilment,
                                    ) =>
                                        dispatchFulfilmentAssociation({
                                            type: "SET",
                                            payload: fulfilment,
                                        })
                                    }
                                />
                            </AccordionDetails>
                        </Accordion>
                    )}
                </LoadingWrapper>
            </Stack>
        </VaporPageStickable>
    );
};
