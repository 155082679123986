import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { FulfilmentActivityEventType } from "../../dtos/FulfilmentActivityEventType";
import { usePostWithErrorHandler } from "../../hooks/usePostWithErrorHandler";

interface UseAssociateTaxPayerToFulfilmentInput {
    taxPayerWorkspaceId: string;
    levyId: string;
    year: number;
}

export function useAssociateTaxPayerToFulfilment({
    taxPayerWorkspaceId,
    levyId,
    year,
}: UseAssociateTaxPayerToFulfilmentInput): {
    associateTaxPayer: () => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading } = usePostWithErrorHandler<boolean>(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${levyId}/years/${year}/tax-payers/${taxPayerWorkspaceId}/activities`,
        {
            description: "Associazione manuale",
            type: FulfilmentActivityEventType.LevyOnGoingV1,
        },
        {
            ...useGetAxiosRequestConfigParams(
                "use-associate-tax-payer-to-fulfilment",
            ),
        },
        false,
    );

    return {
        associateTaxPayer: async () => {
            try {
                return !!(await fetch());
            } catch (e) {
                return false;
            }
        },
        loading: loading,
    };
}
