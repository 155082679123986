import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    useTheme,
} from "@vapor/react-material";
import { ReactElement, useEffect } from "react";
import { v4 } from "uuid";
import { useGetSuggestedFulfilments } from "../../../../../core/usecases/use-get-suggested-fulfilments/useGetSuggestedFulfilments";

export const FulfilmentSuggestedAssociation = (): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    const { data, fetch, loading: isLoading } = useGetSuggestedFulfilments();

    useEffect(() => {
        fetch();
    }, [fetch]);

    return (
        <LoadingWrapper
            loading={isLoading}
            skeletonProps={{ rowsNumber: 5 }}
            variant="skeleton-list"
        >
            {data?.map((suggestion) => (
                <Accordion>
                    <AccordionSummary
                        expandIcon={<FontAwesomeIcon icon={faAngleDown} />}
                    >
                        <Typography
                            color={theme.palette.primary.textTitleColor}
                            variant="subtitle"
                        >
                            {suggestion.description}
                        </Typography>
                        <Typography
                            color={theme.palette.primary.textTitleColor}
                            variant="body"
                        >
                            {t(
                                "views.home.components.fulfilment_suggested_association.table.summary.fulfilments_number",
                                {
                                    defaultValue: "{{number}} Adempimenti",
                                    number: suggestion.suggestedFulfilments
                                        .length,
                                },
                            )}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .textTitleColor
                                            }
                                            variant="body500"
                                        >
                                            {t(
                                                "views.home.components.fulfilment_suggested_association.table.column.deadline",
                                                {
                                                    defaultValue:
                                                        "Data scadenza",
                                                },
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .textTitleColor
                                            }
                                            variant="body500"
                                        >
                                            {t(
                                                "views.home.components.fulfilment_suggested_association.table.column.fulfilment",
                                                {
                                                    defaultValue: "Adempimento",
                                                },
                                            )}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .textTitleColor
                                            }
                                            variant="body500"
                                        >
                                            {t(
                                                "views.home.components.fulfilment_suggested_association.table.column.reason",
                                                {
                                                    defaultValue: "Motivo",
                                                },
                                            )}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {suggestion.suggestedFulfilments.map(
                                    (fulfilment) => (
                                        <TableRow key={v4()}>
                                            <TableCell>
                                                {fulfilment.scadenza}
                                            </TableCell>
                                            <TableCell>
                                                {fulfilment.adempimento}
                                            </TableCell>
                                            <TableCell>
                                                {fulfilment.motivo}
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>
            ))}
        </LoadingWrapper>
    );
};
