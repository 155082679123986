import { useTokenData } from "@onefront/react-sdk";
import { v4 } from "uuid";

import { useMemo } from "react";
import { APP_VERSION, BASE_APP_NAME } from "../../config";

export const useGetAxiosRequestConfigParams = (appNameScope: string) => {
    const data = useTokenData();

    const config = useMemo(() => {
        return {
            withCredentials: true,
            headers: {
                "x-app-name": `${BASE_APP_NAME}.${appNameScope}`,
                "x-app-version": APP_VERSION,
                "x-request-id": v4(),
                "x-correlation-id": v4(),
                "x-user-id": data?.sub,
            },
            // Warning: maybe we should find a way to use qs.stringify in the proper
            // way
            paramsSerializer: {
                indexes: true,
                encode: encodeURI,
            },
        };
    }, [appNameScope, data?.sub]);

    return config;
};
