import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const toastContainerService = () => ({
    target: "$REACT_ROOT_WRAPPER",
    handler: {
        component: ({ children }: any) => (
            <>
                {children}
                <ToastContainer />
            </>
        ),
    },
});
