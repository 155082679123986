import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";
import { FulfilmentActivityDto } from "../../dtos/FulfilmentActivityDto";

interface UseGetFulfilmentActivitiesInput {
    taxPayerWorkspaceId: string;
    levyId: string;
    year: number;
}

export function useGetFulfilmentActivities({
    levyId,
    year,
    taxPayerWorkspaceId,
}: UseGetFulfilmentActivitiesInput): BaseUseCaseResponseDto<
    FulfilmentActivityDto[]
> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        FulfilmentActivityDto[]
    >(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${levyId}/years/${year}/tax-payers/${taxPayerWorkspaceId}/activities`,
        {
            ...useGetAxiosRequestConfigParams("fulfilment-activities"),
        },
    );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
