import routes from "../../../core/commons/routes";
import { ViewWrapper } from "../ViewWrapper";
import { FulfilmentDetail } from "./FulfilmentDetail";

export const fulfilmentDetailFeature = {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
        exact: true,
        path: routes.fulfilmentDetail(),
        element: (
            <ViewWrapper>
                <FulfilmentDetail />
            </ViewWrapper>
        ),
    },
};
