import {
    ListItem,
    Stack,
    TextField,
    Typography,
    useTheme,
    IconButton,
    InputAdornment,
} from "@vapor/react-material";
import { Search } from "@vapor/react-icons";
import Autocomplete from "@mui/material/Autocomplete";
import {
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from "react";
import { TaxPayerDetailsDto } from "../../../../../core/dtos/TaxPayerDto";
import { useTranslation } from "@onefront/react-sdk";

export interface FulfilmentTaxPayerListFilterProps {
    taxPayers: TaxPayerDetailsDto[];
    onFilterChange: (taxPayer: TaxPayerDetailsDto | null) => void;
}

export const FulfilmentTaxPayerListFilter = ({
    taxPayers,
    onFilterChange,
}: FulfilmentTaxPayerListFilterProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");
    const theme = useTheme();

    const [taxPayer, setTaxPayer] = useState<TaxPayerDetailsDto | null>(null);

    const handleTaxPayerChange = useCallback(
        (
            _: SyntheticEvent<Element, Event>,
            value: TaxPayerDetailsDto | null,
        ) => {
            setTaxPayer(value);
        },
        [],
    );

    useEffect(() => {
        onFilterChange(taxPayer);
    }, [taxPayer, onFilterChange]);

    return (
        <Stack alignItems="end" direction="row">
            <Autocomplete
                disabled={taxPayers.length === 0}
                getOptionLabel={(option: TaxPayerDetailsDto) =>
                    option.businessName
                        ? option.businessName
                        : `${option.name} ${option.surname}`
                }
                isOptionEqualToValue={(option, value) => {
                    return option.workspaceId === value.workspaceId;
                }}
                filterOptions={(options, { inputValue }) => {
                    return options.filter((option) => {
                        const name = option.businessName
                            ? option.businessName
                            : `${option.name} ${option.surname}`;
                        return (
                            name
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                            option.taxIdentifier
                                .toLowerCase()
                                .includes(inputValue.toLowerCase()) ||
                            option.vatIdentifier
                                .toLowerCase()
                                .includes(inputValue.toLowerCase())
                        );
                    });
                }}
                noOptionsText={t(
                    "views.home.components.fulfilment_list_header.filters.taxPayer.no_options_text",
                    {
                        defaultValue: "Nessun contribuente trovato",
                    },
                )}
                onChange={handleTaxPayerChange}
                options={taxPayers}
                renderOption={(props, option) => {
                    return (
                        <ListItem {...props} key={option.workspaceId}>
                            <Stack>
                                {option.businessName && (
                                    <Typography>
                                        {option.businessName}
                                    </Typography>
                                )}
                                {!option.businessName && (
                                    <Typography>
                                        {option.name} {option.surname}
                                    </Typography>
                                )}
                                <Typography
                                    color={
                                        theme.palette.primary.textSubduedColor
                                    }
                                >
                                    {option.taxIdentifier}
                                </Typography>
                            </Stack>
                        </ListItem>
                    );
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={t(
                            "views.home.components.fulfilment_list_header.filters.taxPayer.label",
                            {
                                defaultValue: "Contribuente",
                            },
                        )}
                        placeholder={t(
                            "views.home.components.fulfilment_list_header.filters.taxPayer.placeholder",
                            {
                                defaultValue: "Cerca un contribuente",
                            },
                        )}
                        InputLabelProps={{
                            ...params.InputLabelProps,
                            shrink: true,
                        }}
                        InputProps={{
                            ...params.InputProps,
                            sx: { width: "20vw" },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        sx={{
                                            pointerEvents: "none",
                                            background:
                                                "linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%)",
                                        }}
                                    >
                                        <Search color="white" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                padding: 0,
                                paddingLeft: "4px",
                            },
                            "& .MuiAutocomplete-inputRoot": {
                                paddingRight: "0 !important",
                            },
                        }}
                    />
                )}
                value={taxPayer}
            />
        </Stack>
    );
};
