import { useCurrentTenant } from "@drift/oneplatfront";
import { useTranslation } from "@onefront/react-sdk";
import {
    LoadingWrapper,
    VaporPageStickable,
} from "@vapor/react-contrib-tax-compliance";
import { Stack } from "@vapor/react-material";
import { useEffect, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../core/commons/useGetAxiosRequestConfigParams";
import {
    AvailableServices,
    SubscriptionDto,
} from "../../core/dtos/SubscriptionDto";
import { useErrorNotification } from "../../core/hooks/useErrorNotification";
import { useGetWithErrorHandler } from "../../core/hooks/useGetWithErrorHandler";
import { ServiceNotActive } from "../components/service-not-active/ServiceNotActive";

interface ViewWrapperProps {
    children: any;
}

export const ViewWrapper: React.FC<ViewWrapperProps> = ({
    children,
}): React.JSX.Element => {
    const {
        isLoading: isTenantLoading,
        tenant,
        error: tenantError,
    } = useCurrentTenant(true);
    const { t } = useTranslation("tax-manager-app");
    const { showTextMessage } = useErrorNotification();

    const [isTenantEnabled, setIsTenantEnabled] = useState(false);

    const { fetch, loading } = useGetWithErrorHandler<SubscriptionDto[]>(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/subscriptions`,
        {
            ...useGetAxiosRequestConfigParams("fulfilments"),
            lazy: true,
        },
        false,
    );

    useEffect(() => {
        async function fetchSubscriptions() {
            try {
                const subscriptions = await fetch();
                if (
                    subscriptions &&
                    subscriptions.data.length > 0 &&
                    subscriptions.data.find(
                        (subscription) =>
                            subscription.serviceId ===
                                AvailableServices.Fulfilment &&
                            subscription.isActive === true,
                    )
                ) {
                    setIsTenantEnabled(true);
                }
            } catch (e) {}
        }

        if (tenant) fetchSubscriptions();

        return () => {
            setIsTenantEnabled(false);
        };
    }, [fetch, tenant]);

    useEffect(() => {
        if (tenantError) {
            showTextMessage(tenantError);
        }
    }, [showTextMessage, tenantError]);

    return !tenant ? (
        <VaporPageStickable>
            <LoadingWrapper
                loading={true}
                spinnerProps={{
                    loadingText: t("views.view_wrapper.loading_spinner_text", {
                        defaultValue: "Seleziona una company",
                    }),
                }}
            />
        </VaporPageStickable>
    ) : loading || isTenantLoading ? (
        <Stack>
            <LoadingWrapper
                loading={loading}
                spinnerProps={{
                    loadingText: t("views.view_wrapper.check_active_service", {
                        defaultValue: "Controllo stato del servizio",
                    }),
                }}
            >
                <VaporPageStickable />
            </LoadingWrapper>
        </Stack>
    ) : isTenantEnabled ? (
        <>{children}</>
    ) : (
        <ServiceNotActive />
    );
};
