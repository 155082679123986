import { useCurrentTenant } from "@drift/oneplatfront";
import { useCallback } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { KpisDto } from "../../dtos/KpisDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface FetchKPIsArgs {
    deadlineFrom: Date;
    deadlineTo: Date;
}

export function useGetKPIs(): BaseUseCaseResponseDto<KpisDto, FetchKPIsArgs> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        KpisDto,
        FetchKPIsArgs
    >(`rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/kpis`, {
        ...useGetAxiosRequestConfigParams("use-get-kpis"),
    });

    const fetchData = useCallback(
        async (args?: { params?: FetchKPIsArgs }) => {
            return await fetch({
                params: {
                    deadlineFrom:
                        (args?.params?.deadlineFrom?.getTime() as any) || 0,
                    deadlineTo:
                        (args?.params?.deadlineTo?.getTime() as any) || 0,
                },
            });
        },
        [fetch],
    );

    return {
        data: data,
        error: error,
        fetch: fetchData,
        loading: loading,
    };
}
