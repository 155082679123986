import { useTranslation } from "@onefront/react-sdk";
import { Tag } from "@vapor/react-custom";
import { useTheme } from "@vapor/react-material";
import { FulfilmentStatus } from "../../../core/dtos/FulfilmentDetailDto";
import { getColorAndVariantByFulfilmentStatus } from "../../../utils/getColorAndVariantByFulfilmentStatus";

interface FulfilmentStatusChipProps {
    plural?: boolean;
    status: FulfilmentStatus;
}
export const FulfilmentStatusChip: React.FC<FulfilmentStatusChipProps> = ({
    plural = false,
    status,
}): JSX.Element => {
    const { t } = useTranslation("tax-manager-app");
    const theme = useTheme();

    const customization = getColorAndVariantByFulfilmentStatus(status, theme);

    return (
        <Tag
            label={t(
                `components.fulfilment_status_chip.status.${
                    plural ? "plural." : ""
                }${status?.toLowerCase() ?? "not_available"}`,
                { defaultValue: "Stato non disponibile" },
            )}
            type={customization.vaporColor}
            variant={customization.variant}
        />
    );
};
