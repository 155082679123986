import { faAngleDown, faAngleUp } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import { Button, Divider, Stack, useTheme } from "@vapor/react-material";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { FulfilmentStatus } from "../../../../../core/dtos/FulfilmentDetailDto";
import { useGetKPIs } from "../../../../../core/usecases/use-get-kpis/useGetKPIs";
import { FulfilmentListHeaderKPICard } from "../fulfilment-list-header-kpi-card/FulfilmenListHeaderKPICard";

interface FulfilmentListHeaderKPIsProps {
    accordionRight?: JSX.Element;
    accordionLeft?: JSX.Element;
    deadlineFrom: Date;
    deadlineTo: Date;
    kpis: FulfilmentStatus[];
    onChangeSelectedKPIs: (selectedKPIs: FulfilmentStatus[]) => unknown;
}

export const FulfilmentListHeaderKPIs = ({
    accordionRight,
    accordionLeft,
    deadlineFrom,
    deadlineTo,
    kpis,
    onChangeSelectedKPIs,
}: FulfilmentListHeaderKPIsProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");
    const theme = useTheme();

    const [openKPIs, setOpenKPIs] = useState(true);

    const { fetch, loading, data } = useGetKPIs();

    useEffect(() => {
        fetch({ params: { deadlineFrom, deadlineTo } });
    }, [deadlineFrom, deadlineTo, fetch]);

    const handleToggleKPIs = useCallback(() => {
        setOpenKPIs((previous) => !previous);
    }, []);

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
            >
                <Stack direction="row" spacing={2}>
                    <Button
                        startIcon={
                            <VaporIcon
                                color="info"
                                icon={openKPIs ? faAngleUp : faAngleDown}
                            />
                        }
                        onClick={handleToggleKPIs}
                    >
                        <Stack direction="row" spacing={1}>
                            <LoadingWrapper
                                loading={loading}
                                spinnerProps={{ loadingText: "" }}
                                variant="spinner"
                            >
                                <Typography
                                    color={theme.palette.primary.textTitleColor}
                                    variant="bodyLarge500"
                                >
                                    {data?.statusCounters
                                        ?.map((kpi) => kpi.count)
                                        .reduce((acc, curr) => acc + curr, 0) ??
                                        0}
                                </Typography>
                            </LoadingWrapper>
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                ml={1}
                                variant="bodyLarge"
                            >
                                {t(
                                    "views.home.components.fulfilment_list_header.kpis.accordion",
                                    {
                                        defaultValue: "Adempimenti previsti",
                                    },
                                )}
                            </Typography>

                            <Divider orientation="vertical" light flexItem />

                            <LoadingWrapper
                                loading={loading}
                                spinnerProps={{ loadingText: "" }}
                                variant="spinner"
                            >
                                <Typography
                                    color={theme.palette.primary.textTitleColor}
                                    variant="bodyLarge500"
                                >
                                    {data?.uniqueTaxPayersCount ?? 0}
                                </Typography>
                            </LoadingWrapper>
                            <Typography
                                color={theme.palette.primary.textTitleColor}
                                ml={1}
                                variant="bodyLarge"
                            >
                                {t(
                                    "views.home.components.fulfilment_list_header.kpis.accordion.asd",
                                    {
                                        defaultValue: "Contribuenti",
                                    },
                                )}
                            </Typography>
                        </Stack>
                    </Button>
                </Stack>
                {accordionLeft}
                {accordionRight}
            </Stack>
            {openKPIs && (
                <Stack direction="row" spacing={2} alignItems="end">
                    {[
                        FulfilmentStatus.ERROR,
                        FulfilmentStatus.CLOSED,
                        FulfilmentStatus.COMPLETED,
                        FulfilmentStatus.PENDING,
                    ].map((status) => (
                        <FulfilmentListHeaderKPICard
                            fulfilmentStatus={status}
                            isSelected={kpis.indexOf(status) > -1}
                            loading={loading}
                            key={status}
                            numberOfFulfilmentStatus={
                                data?.statusCounters
                                    ?.filter((kpi) => kpi.status === status)
                                    .map((kpi) => kpi.count)
                                    .reduce((acc, curr) => acc + curr, 0) ?? 0
                            }
                            onClickKPI={(status) => {
                                const newSelectedKPIs = [...kpis];
                                const statusIndex =
                                    newSelectedKPIs.indexOf(status);

                                if (statusIndex === -1) {
                                    newSelectedKPIs.push(status);
                                } else {
                                    newSelectedKPIs.splice(statusIndex, 1);
                                }

                                onChangeSelectedKPIs(newSelectedKPIs);
                            }}
                        />
                    ))}
                </Stack>
            )}
        </>
    );
};
