import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { useErrorNotification } from "../../hooks/useErrorNotification";

interface DeleteCustomFulfilmentPayload {
    levyId: string;
}

export function useDeleteCustomFulfilment(): {
    deleteCustomFulfilment: (
        payload: DeleteCustomFulfilmentPayload,
    ) => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(false);
    const config = useGetAxiosRequestConfigParams(
        "use-delete-custom-fulfilment",
    );
    const { showAxiosErrorMessage } = useErrorNotification();

    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const deleteCustomFulfilment = useCallback(
        async (payload: DeleteCustomFulfilmentPayload) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    data: {},
                    lazy: true,
                    method: "delete",
                    stateless: true,
                    url: `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/custom/${payload.levyId}`,
                });
                return true;
            } catch (error: any) {
                setError(error);
                return false;
            } finally {
                setLoading(false);
            }
        },
        [config, tenant?.id],
    );

    useEffect(() => {
        if (error) showAxiosErrorMessage(error);
    }, [error, showAxiosErrorMessage]);

    return {
        deleteCustomFulfilment,
        loading,
    };
}
