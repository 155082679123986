import { faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Avatar,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import { ReactElement, useState } from "react";
import { FulfilmentDto } from "../../../../../core/dtos/FulfilmentDto";
import { OperatorDto } from "../../../../../core/dtos/OperatorDto";
import { useUpdateFulfilmentOperator } from "../../../../../core/usecases/use-update-fulfilment-operator/useUpdateFulfilmentOperator";

export interface FulfilmentOperatorAvatarProps {
    availableOperators: OperatorDto[];
    currentOperator?: string;
    isLoading: boolean;
    selectedFulfilment: FulfilmentDto;
    taxPayerWorkspaceId: string;
}

export const FulfilmentOperatorAvatar = ({
    availableOperators,
    currentOperator,
    isLoading,
    selectedFulfilment,
    taxPayerWorkspaceId,
}: FulfilmentOperatorAvatarProps): ReactElement => {
    const theme = useTheme();

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedOperatorMenuIndex, setSelectedOperatorMenuIndex] =
        useState<number>();
    const [_currentOperator, _setcurrentOperator] = useState(currentOperator);

    const { updateOperator, loading: isLoadingUpdateOperator } =
        useUpdateFulfilmentOperator();

    const handleCloseMenu = () => setMenuAnchorEl(null);
    const handleOpenMenu = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (availableOperators.length > 0) {
            setMenuAnchorEl(event.currentTarget);
        }
    };

    return (
        <LoadingWrapper
            loading={isLoading}
            spinnerProps={{ loadingText: "", sx: { width: 32 } }}
            variant="spinner"
        >
            <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                onClose={handleCloseMenu}
                open={!!menuAnchorEl}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                {availableOperators.map((operator, index) => (
                    <MenuItem
                        disabled={isLoadingUpdateOperator}
                        key={index}
                        onClick={async (
                            event: React.MouseEvent<HTMLLIElement>,
                        ) => {
                            event.stopPropagation();
                            setSelectedOperatorMenuIndex(index);

                            const success = await updateOperator({
                                levyId: selectedFulfilment.levyId,
                                operator: {
                                    id: operator.id,
                                    name: operator.name,
                                    surname: operator.surname,
                                },
                                taxPayerWorkspaceId: taxPayerWorkspaceId,
                                year: dayjs(selectedFulfilment.deadline).year(),
                            });

                            if (success) {
                                _setcurrentOperator(
                                    `${operator.name} ${operator.surname}`,
                                );
                            }

                            setSelectedOperatorMenuIndex(undefined);
                        }}
                    >
                        <Stack alignItems="center" direction="row" gap={1}>
                            <LoadingWrapper
                                loading={
                                    isLoadingUpdateOperator &&
                                    index === selectedOperatorMenuIndex
                                }
                                spinnerProps={{
                                    loadingText: "",
                                    sx: {
                                        alignItems: "center",
                                        height: 32,
                                        width: 32,
                                    },
                                }}
                                variant="spinner"
                            />
                            {!(
                                isLoadingUpdateOperator &&
                                index === selectedOperatorMenuIndex
                            ) && (
                                <Avatar
                                    sx={{
                                        bgcolor:
                                            theme.palette.primary.background,
                                        height: 32,
                                        width: 32,
                                    }}
                                >
                                    <Typography
                                        color={
                                            theme.palette.primary
                                                .textSubduedColor
                                        }
                                    >
                                        {`${operator.name.charAt(0)}${operator.surname.charAt(0)}`}
                                    </Typography>
                                </Avatar>
                            )}

                            {`${operator.name} ${operator.surname}`}
                        </Stack>
                    </MenuItem>
                ))}
            </Menu>
            <Avatar
                onClick={handleOpenMenu}
                sx={{
                    bgcolor: theme.palette.primary.background,
                    cursor:
                        isLoadingUpdateOperator ||
                        availableOperators.length === 0
                            ? "default"
                            : "pointer",
                    height: 32,
                    width: 32,
                }}
            >
                {_currentOperator ? (
                    <Tooltip title={currentOperator} placement="left">
                        <Typography
                            color={theme.palette.primary.textSubduedColor}
                        >
                            {`${_currentOperator.charAt(0)}${_currentOperator.split(" ").pop()?.charAt(0)}`}
                        </Typography>
                    </Tooltip>
                ) : (
                    <FontAwesomeIcon
                        color={theme.palette.primary.textSubduedColor}
                        icon={faUser}
                    />
                )}
            </Avatar>
        </LoadingWrapper>
    );
};
