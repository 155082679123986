import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { TaxPayerDetailsDto } from "../../dtos/TaxPayerDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface UseGetFulfilmentTaxPayersInput {
    levyId: string;
    year: number;
}

export function useGetFulfilmentTaxPayers(
    input: UseGetFulfilmentTaxPayersInput,
): BaseUseCaseResponseDto<TaxPayerDetailsDto[]> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        TaxPayerDetailsDto[]
    >(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${input.levyId}/years/${input.year}/tax-payers`,
        {
            ...useGetAxiosRequestConfigParams("fulfilments"),
        },
    );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
