import { useTranslation } from "@onefront/react-sdk";
import { Tag } from "@vapor/react-custom";
import { ReactElement } from "react";

export enum ActivityStatus {
    CLOSED = "closed",
    COMPLETED = "completed",
    ERROR = "error",
    PENDING = "pending",
}

// TODO colors not present in theme yet
const getColorFromStatus = (
    status: ActivityStatus,
):
    | "russianViolet"
    | "islamicGreen"
    | "rustyRed"
    | "blueSapphire"
    | "maximumPurple"
    | "mediumSlateBlue"
    | "prune"
    | "royalFuchsia"
    | undefined => {
    switch (status) {
        case ActivityStatus.CLOSED:
            return "russianViolet";
        case ActivityStatus.COMPLETED:
            return "islamicGreen";
        case ActivityStatus.ERROR:
            return "rustyRed";
        case ActivityStatus.PENDING:
            return "blueSapphire";
        default:
            return undefined;
    }
};

export const getLabelFromStatus = (
    status: ActivityStatus,
    t: (key: string, options?: any) => string,
    count?: number,
): string => {
    const variant = count && count > 0 ? "plural" : "singular";
    switch (status) {
        case ActivityStatus.CLOSED:
            return t(`entity.fulfilment.activity.status.${variant}.closed`, {
                defaultValue: "Chiuso",
            });
        case ActivityStatus.COMPLETED:
            return t(`entity.fulfilment.activity.status.${variant}.completed`, {
                defaultValue: "Completato",
            });
        case ActivityStatus.ERROR:
            return t(`entity.fulfilment.activity.status.${variant}.error`, {
                defaultValue: "Errore",
            });
        case ActivityStatus.PENDING:
            return t("entity.fulfilment.activity.status.pending", {
                defaultValue: "In lavorazione",
            });
        default:
            return "";
    }
};

interface ActivityCounterProps {
    count?: number;
    status: ActivityStatus;
}

export const ActivityCounter = ({
    count,
    status,
}: ActivityCounterProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");

    return (
        <Tag
            label={`${getLabelFromStatus(status, t, count)} ${
                !!count ? `(${count})` : ""
            }`}
            variant="ghost"
            type={getColorFromStatus(status)}
        />
    );
};
