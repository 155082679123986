import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { OperatorDto } from "../../dtos/OperatorDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

export function useGetOperators(): BaseUseCaseResponseDto<OperatorDto[]> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        OperatorDto[]
    >(`rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/operators`, {
        ...useGetAxiosRequestConfigParams("use-get-operators"),
    });

    return {
        data,
        error,
        fetch,
        loading,
    };
}
