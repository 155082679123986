import { faSquare, faSquareCheck } from "@fortawesome/pro-light-svg-icons";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "@onefront/react-sdk";
import { VaporToolbar } from "@vapor/react-custom";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { VaporIcon } from "@vapor/react-icons";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    ListItem,
    Stack,
    TextField,
    useTheme,
} from "@vapor/react-material";
import {
    AdapterDateFns,
    DatePicker,
    LocalizationProvider,
} from "@vapor/react-x-date-pickers";
import it from "date-fns/locale/it";
import dayjs from "dayjs";
import {
    ChangeEvent,
    ReactElement,
    SyntheticEvent,
    useEffect,
    useState,
} from "react";
import { AssociableTaxPayerDto } from "../../../../../core/dtos/AssociableTaxPayerDto";
import { useSuccessNotification } from "../../../../../core/hooks/useSuccessNotification";
import {
    ResponseStatus,
    useAddCustomFulfilment,
} from "../../../../../core/usecases/use-add-custom-fulfilment/useAddCustomFulfilment";
import { useGetAssociableTaxPayers } from "../../../../../core/usecases/use-get-associable-tax-payers/useGetAssociableTaxPayers";
import { DialogWarning } from "../dialog-warning/DialogWarning";

interface AddCustomFulfilmentFormModel {
    associableTaxPayers: AssociableTaxPayerDto[];
    deadline: Date;
    fulfilmentName: string;
}

interface AddCustomFulfilmentProps {
    onAddCustomFulfilment: () => unknown;
    onClickCancel: () => unknown;
}
export const AddCustomFulfilment = ({
    onAddCustomFulfilment,
    onClickCancel,
}: AddCustomFulfilmentProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");
    const { showTextMessage } = useSuccessNotification();
    const theme = useTheme();

    const [canAddCustomFulfilment, setCanAddCustomFulfilment] = useState(true);
    const [openWarning, setOpenWarning] = useState(false);
    const [taxPayersInitialized, setTaxPayersInitialized] = useState(false);

    const {
        fetch: fetchAssociableTaxPayers,
        loading: isLoadingAssociableTaxPayers,
        data: associableTaxPayers,
    } = useGetAssociableTaxPayers();

    const [formModel, setFormModel] = useState<AddCustomFulfilmentFormModel>({
        associableTaxPayers: [],
        deadline: new Date(),
        fulfilmentName: "",
    });

    const { addCustomFulfilment, loading: isAddCustomFulfilmentLoading } =
        useAddCustomFulfilment();

    useEffect(() => {
        if (!taxPayersInitialized) {
            fetchAssociableTaxPayers();
            setTaxPayersInitialized(true);
        }
    }, [fetchAssociableTaxPayers, taxPayersInitialized]);

    useEffect(() => {
        setCanAddCustomFulfilment(
            !!formModel.deadline && !!formModel.fulfilmentName,
        );
    }, [formModel.deadline, formModel.fulfilmentName]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const response = await addCustomFulfilment({
            deadline: formModel.deadline,
            fulfilmentName: formModel.fulfilmentName,
            workspaceIds: formModel.associableTaxPayers.map(
                (wp) => wp.workspaceId,
            ),
        });

        if (response === ResponseStatus.OK) {
            showTextMessage(
                t("views.home.components.add_custom_fulfilment.success", {
                    defaultValue: "Adempimento caricato con successo",
                }),
            );
            onAddCustomFulfilment();
        } else {
            if (response === ResponseStatus.CONFLICT) {
                setOpenWarning(true);
            }
        }
    };

    const handleOnConfirm = () => {
        setOpenWarning(false);
    };

    const handleOnDismiss = () => {
        if (onClickCancel) onClickCancel();
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack p={3}>
                <Stack justifyContent="space-between">
                    <Stack gap={1}>
                        <TextField
                            error={!formModel.fulfilmentName}
                            fullWidth
                            label={t(
                                "views.home.components.add_custom_fulfilment.fulfilment.name",
                                { defaultValue: "Nome adempimento *" },
                            )}
                            onChange={(
                                event: ChangeEvent<HTMLInputElement>,
                            ) => {
                                setFormModel((prev) => {
                                    return {
                                        ...prev,
                                        fulfilmentName: event.target.value,
                                    };
                                });
                            }}
                            value={formModel.fulfilmentName}
                        />

                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            adapterLocale={it}
                        >
                            <DatePicker
                                label={t(
                                    "views.home.components.add_custom_fulfilment.fulfilment.deadline",
                                    {
                                        defaultValue: "Data di scadenza *",
                                    },
                                )}
                                onChange={(newValue) => {
                                    setFormModel((prev) => ({
                                        ...prev,
                                        deadline: dayjs(newValue as Date)
                                            .hour(12)
                                            .minute(0)
                                            .second(0)
                                            .millisecond(0)
                                            .toDate(),
                                    }));
                                }}
                                value={formModel.deadline}
                            />
                        </LocalizationProvider>
                        {/* FIXME: use autocomplete from vapor once fullwidth is fixed and render input's placeholder displays correctly on multiline */}
                        <Autocomplete
                            disabled={
                                !taxPayersInitialized ||
                                isLoadingAssociableTaxPayers
                            }
                            disableCloseOnSelect
                            filterOptions={(
                                options: AssociableTaxPayerDto[],
                                { inputValue },
                            ) =>
                                options.filter(
                                    (option) =>
                                        option.fiscalCode
                                            .toLowerCase()
                                            .includes(
                                                inputValue.toLowerCase(),
                                            ) ||
                                        option.name
                                            .toLowerCase()
                                            .includes(
                                                inputValue.toLowerCase(),
                                            ) ||
                                        option.vatNumber
                                            .toLowerCase()
                                            .includes(inputValue.toLowerCase()),
                                )
                            }
                            fullWidth
                            getOptionLabel={(option: AssociableTaxPayerDto) =>
                                option.name
                            }
                            isOptionEqualToValue={(option, value) => {
                                return option.workspaceId === value.workspaceId;
                            }}
                            multiple
                            noOptionsText={t(
                                "views.home.components.add_custom_fulfilment.fulfilment.workspace_ids",
                                {
                                    defaultValue:
                                        "Seleziona uno o più contribuenti *",
                                },
                            )}
                            onChange={(
                                _: SyntheticEvent<Element, Event>,
                                value: AssociableTaxPayerDto[],
                            ) => {
                                setFormModel((prev) => ({
                                    ...prev,
                                    associableTaxPayers: value,
                                }));
                            }}
                            options={associableTaxPayers || []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t(
                                        "views.home.components.fulfilment_list_header.filters.taxPayer.label",
                                        {
                                            defaultValue: "Contribuente",
                                        },
                                    )}
                                    placeholder={t(
                                        "views.home.components.fulfilment_list_header.filters.taxPayer.placeholder",
                                        {
                                            defaultValue:
                                                "Cerca un contribuente",
                                        },
                                    )}
                                    InputLabelProps={{
                                        ...params.InputLabelProps,
                                        shrink:
                                            formModel.associableTaxPayers
                                                .length === 0,
                                    }}
                                    // InputProps={{
                                    //     ...params.InputProps,
                                    //     sx: { width: "100%" },
                                    // }}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            padding: 0,
                                            paddingLeft: "4px",
                                        },
                                    }}
                                />
                            )}
                            renderOption={(
                                props,
                                option: AssociableTaxPayerDto,
                                { selected },
                            ) => (
                                <ListItem {...props} key={option.workspaceId}>
                                    <Stack direction="row">
                                        <Stack>
                                            <Checkbox
                                                icon={
                                                    <VaporIcon
                                                        icon={faSquare}
                                                        color={
                                                            theme.palette
                                                                .primary.cadet
                                                        }
                                                    />
                                                }
                                                checkedIcon={
                                                    <VaporIcon
                                                        icon={faSquareCheck}
                                                        color={
                                                            theme.palette
                                                                .primary.cadet
                                                        }
                                                    />
                                                }
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                        </Stack>
                                        <Stack>
                                            {option.name && (
                                                <Typography>
                                                    {option.name}
                                                </Typography>
                                            )}
                                            {option.vatNumber && (
                                                <Typography
                                                    color={
                                                        theme.palette.primary
                                                            .textSubduedColor
                                                    }
                                                >
                                                    {option.vatNumber}
                                                </Typography>
                                            )}
                                            {option.fiscalCode && (
                                                <Typography
                                                    color={
                                                        theme.palette.primary
                                                            .textSubduedColor
                                                    }
                                                >
                                                    {option.fiscalCode}
                                                </Typography>
                                            )}
                                        </Stack>
                                    </Stack>
                                </ListItem>
                            )}
                            value={formModel.associableTaxPayers}
                        />
                    </Stack>
                    <Box
                        sx={{
                            ml: -3,
                        }}
                    >
                        <VaporToolbar
                            contentLeft={[
                                <Button
                                    disabled={isAddCustomFulfilmentLoading}
                                    onClick={onClickCancel}
                                    variant="outlined"
                                >
                                    {t(
                                        "views.home.components.add_custom_fulfilment.buttons.cancel",
                                        {
                                            defaultValue: "Annulla",
                                        },
                                    )}
                                </Button>,
                            ]}
                            contentRight={[
                                <Button
                                    disabled={
                                        !canAddCustomFulfilment ||
                                        isAddCustomFulfilmentLoading
                                    }
                                    type="submit"
                                    variant="contained"
                                >
                                    {t(
                                        "views.home.components.add_custom_fulfilment.buttons.submit",
                                        {
                                            defaultValue: "Conferma",
                                        },
                                    )}
                                    {isAddCustomFulfilmentLoading && (
                                        <CircularProgress
                                            color="inherit"
                                            sx={{ ml: 1 }}
                                            size={16}
                                        />
                                    )}
                                </Button>,
                            ]}
                            size="medium"
                            variant="regular"
                        />
                    </Box>
                    {openWarning && (
                        <DialogWarning
                            dialogContentText={t(
                                "views.home.components.add_custom_fulfilment_warning.existingCustomFulfilment.dialogContent",
                                {
                                    defaultValue:
                                        "E' già presente un adempimento personalizzato con stesso nome e stessa data di scadenza. Per inserire un nuovo adempimento modificare la descrizione o la data di scadenza.",
                                },
                            )}
                            dialogPrimaryButtonText={t(
                                "views.home.components.add_custom_fulfilment_warning.existingCustomFulfilment.edit",
                                { defaultValue: "Modifica" },
                            )}
                            dialogSecondaryButtonText={t(
                                "views.home.components.add_custom_fulfilment_warning.existingCustomFulfilment.cancel",
                                { defaultValue: "Annulla" },
                            )}
                            dialogTitleText={t(
                                "views.home.components.add_custom_fulfilment_warning.existingCustomFulfilment.dialogTitle",
                                { defaultValue: "Attenzione" },
                            )}
                            onConfirm={handleOnConfirm}
                            onDismiss={handleOnDismiss}
                        ></DialogWarning>
                    )}
                </Stack>
            </Stack>
        </form>
    );
};
