import { useCurrentTenant } from "@drift/oneplatfront";
import { AxiosResponse } from "axios";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { CancelLastActivityDto } from "../../dtos/CancelLastActivityDto";
import { usePostWithErrorHandler } from "../../hooks/usePostWithErrorHandler";

interface UseCancelLastActivityInput {
    taxPayerWorkspaceId: string;
    levyId: string;
    year: number;
}

export function useCancelLastActivity({
    taxPayerWorkspaceId,
    levyId,
    year,
}: UseCancelLastActivityInput): {
    cancelLastActivity: () => Promise<AxiosResponse<CancelLastActivityDto>>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading } = usePostWithErrorHandler<CancelLastActivityDto>(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${levyId}/years/${year}/tax-payers/${taxPayerWorkspaceId}/activities/cancel-last-activity`,
        undefined,
        {
            ...useGetAxiosRequestConfigParams("use-close-fulfilment"),
        },
        false,
    );

    return {
        cancelLastActivity: async () => {
            return fetch();
        },
        loading: loading,
    };
}
