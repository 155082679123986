import { useCurrentTenant } from "@drift/oneplatfront";
import { useCallback } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { AssociableTaxPayerDto } from "../../dtos/AssociableTaxPayerDto";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

interface FetchAssociableTaxPayersParams {
    levyId?: string;
    year?: number;
}

export function useGetAssociableTaxPayers(): BaseUseCaseResponseDto<
    AssociableTaxPayerDto[],
    FetchAssociableTaxPayersParams
> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        AssociableTaxPayerDto[],
        FetchAssociableTaxPayersParams
    >(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/associable-tax-payers`,
        {
            ...useGetAxiosRequestConfigParams("use-get-associable-tax-payers"),
        },
    );

    const fetchData = useCallback(
        async (args?: { params?: FetchAssociableTaxPayersParams }) => {
            return await fetch({
                params: {
                    ...(!!args?.params?.levyId
                        ? { levyId: args.params?.levyId }
                        : {}),
                    ...(!!args?.params?.year
                        ? { year: args.params?.year }
                        : {}),
                },
            });
        },
        [fetch],
    );

    return {
        data: data,
        error: error,
        fetch: fetchData,
        loading: loading,
    };
}
