import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Stack,
} from "@vapor/react-material";
import { ReactElement, useState } from "react";

export interface FulfilmentDetailFooterPlaceholderProps {
    isFulfilmentCloseable: boolean;
    loading: boolean;
    onClickCancelLastActivity: () => unknown;
    onClickCloseFulfilmentButton: () => unknown;
}

export const FulfilmentDetailFooter = ({
    isFulfilmentCloseable,
    loading,
    onClickCancelLastActivity,
    onClickCloseFulfilmentButton,
}: FulfilmentDetailFooterPlaceholderProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");
    const [isOpenCloseFulfilmentDialog, setIsOpenCloseFulfilmentDialog] =
        useState(false);
    const [isOpenCancelLastActivityDialog, setIsOpenCancelLastActivityDialog] =
        useState(false);

    const onClickAbortDialogs = (
        event: {},
        reason?: "backdropClick" | "escapeKeyDown",
    ) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            // Set 'open' to false, however you would do that with your particular code.
            setIsOpenCloseFulfilmentDialog(false);
            setIsOpenCancelLastActivityDialog(false);
        }
    };

    return (
        <Stack
            direction="row"
            spacing={4}
            p={4}
            alignItems="center"
            justifyContent="space-between"
        >
            <Stack direction="row" spacing={1}>
                <Button
                    onClick={() => setIsOpenCancelLastActivityDialog(true)}
                    disabled={loading}
                    variant="outlined"
                >
                    <Typography ml={1}>
                        {t(
                            "views.fulfilment_detail.components.fulfilment_detail_footer.buttons.cancel_last_activity",
                            { defaultValue: "Annulla attività" },
                        )}
                    </Typography>
                </Button>
            </Stack>

            <Stack direction="row" spacing={3}>
                <Button
                    onClick={() => setIsOpenCloseFulfilmentDialog(true)}
                    disabled={!isFulfilmentCloseable}
                    variant="contained"
                >
                    <Typography ml={1}>
                        {t(
                            "views.fulfilment_detail.components.fulfilment_detail_footer.buttons.close_fulfilment",
                            { defaultValue: "Chiudi adempimento" },
                        )}
                    </Typography>
                </Button>
                <Dialog
                    open={isOpenCancelLastActivityDialog}
                    onClose={onClickAbortDialogs}
                    aria-labelledby="cancel-activity-dialog-title"
                    aria-describedby="cancel-activity-dialog-description"
                >
                    <DialogTitle id="cancel-activity-dialog-title">
                        {t(
                            "views.fulfilment_detail.components.fulfilment_detail_footer.cancel_last_activity_dialog.title",
                            { defaultValue: "Annullamento ultima attività" },
                        )}
                    </DialogTitle>
                    <Divider variant="fullWidth" />
                    <DialogContent>
                        <DialogContentText id="cancel-activity-dialog-description">
                            {t(
                                "views.fulfilment_detail.components.fulfilment_detail_footer.cancel_last_activity_dialog.title.content",
                                {
                                    defaultValue:
                                        "Stai per annullare l'ultima attività. Questa operazione ripristinerà lo stato precedente. Sei sicuro?",
                                },
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={loading}
                            onClick={onClickAbortDialogs}
                            variant="outlined"
                        >
                            {t(
                                "views.fulfilment_detail.components.fulfilment_detail_footer.cancel_last_activity_dialog.actions.abort",
                                { defaultValue: "Annulla" },
                            )}
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={async () => {
                                await onClickCancelLastActivity();
                                setIsOpenCancelLastActivityDialog(false);
                            }}
                            variant="contained"
                        >
                            {t(
                                "views.fulfilment_detail.components.fulfilment_detail_footer.cancel_last_activity_dialog.actions.confirm",
                                { defaultValue: "Conferma" },
                            )}
                            {loading && (
                                <CircularProgress
                                    color="inherit"
                                    sx={{ ml: 1 }}
                                    size={16}
                                />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={isOpenCloseFulfilmentDialog}
                    onClose={onClickAbortDialogs}
                    aria-labelledby="close-fulfilment-dialog-title"
                    aria-describedby="close-fulfilment-dialog-description"
                >
                    <DialogTitle id="close-fulfilment-dialog-title">
                        {t(
                            "views.fulfilment_detail.components.fulfilment_detail_footer.close_fulfilment_dialog.title",
                            { defaultValue: "Sincronizzazione dati" },
                        )}
                    </DialogTitle>
                    <Divider variant="fullWidth" />
                    <DialogContent>
                        <DialogContentText id="close-fulfilment-dialog-description">
                            {t(
                                "views.fulfilment_detail.components.fulfilment_detail_footer.close_fulfilment_dialog.title.content",
                                {
                                    defaultValue:
                                        "Procedendo con questa azione, l'adempimento non riceverà più aggiornamenti da altre operazioni all'interno dell'applicativo",
                                },
                            )}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={loading}
                            onClick={onClickAbortDialogs}
                            variant="outlined"
                        >
                            {t(
                                "views.fulfilment_detail.components.fulfilment_detail_footer.close_fulfilment_dialog.actions.abort",
                                { defaultValue: "Annulla" },
                            )}
                        </Button>
                        <Button
                            disabled={loading}
                            onClick={async () => {
                                await onClickCloseFulfilmentButton();
                                setIsOpenCloseFulfilmentDialog(false);
                            }}
                            variant="contained"
                        >
                            {t(
                                "views.fulfilment_detail.components.fulfilment_detail_footer.close_fulfilment_dialog.actions.confirm",
                                { defaultValue: "Conferma" },
                            )}
                            {loading && (
                                <CircularProgress
                                    color="inherit"
                                    sx={{ ml: 1 }}
                                    size={16}
                                />
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Stack>
        </Stack>
    );
};
