import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Box, List, ListItem, Stack, useTheme } from "@vapor/react-material";
import { ReactElement } from "react";
import { AssociableTaxPayerDto } from "../../../../../core/dtos/AssociableTaxPayerDto";
import { FulfilmentAssociationTaxPayer } from "../fulfilment-association-tax-payer/FulfilmentAssociationTaxPayer";

const EmptyData = (): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    return (
        <Box
            sx={{
                textAlign: "center",
            }}
        >
            <Typography
                color={theme.palette.primary.textTitleColor}
                variant="h5"
            >
                {t(
                    "views.home.components.fulfilment_association.no_taxPayers",
                    { defaultValue: "Nessun cliente da associare" },
                )}
            </Typography>
        </Box>
    );
};

export interface FulfilmentAssociationProps {
    levyId: string;
    isLoading: boolean;
    onAssociateTaxPayer: () => unknown;
    taxPayers: AssociableTaxPayerDto[];
    year: number;
}

export const FulfilmentAssociation = ({
    isLoading,
    levyId,
    onAssociateTaxPayer,
    taxPayers,
    year,
}: FulfilmentAssociationProps): ReactElement => {
    return (
        <Stack spacing={4}>
            <LoadingWrapper loading={isLoading} variant="skeleton-list">
                {taxPayers && taxPayers.length > 0 ? (
                    <List disablePadding style={{ marginTop: 0 }}>
                        {taxPayers.map((taxPayer) => (
                            <ListItem
                                key={taxPayer.workspaceId}
                                disableGutters
                                sx={{
                                    "&.MuiListItem-root": {
                                        padding: 0,
                                        border: "1px solid #B9C8D0",
                                        borderLeft: "1px solid transparent",
                                        borderRight: "1px solid transparent",
                                    },
                                    "&.MuiListItem-root:hover": {
                                        borderLeft: "1px solid transparent",
                                        borderRight: "1px solid transparent",
                                    },
                                }}
                            >
                                <FulfilmentAssociationTaxPayer
                                    levyId={levyId}
                                    onAssociateTaxPayer={onAssociateTaxPayer}
                                    taxPayer={taxPayer}
                                    year={year}
                                />
                            </ListItem>
                        ))}
                    </List>
                ) : (
                    <EmptyData />
                )}
            </LoadingWrapper>
        </Stack>
    );
};
