import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { OperatorDto } from "../../dtos/OperatorDto";
import { useErrorNotification } from "../../hooks/useErrorNotification";

interface UpdateOperatorPayload {
    levyId: string;
    operator: OperatorDto;
    taxPayerWorkspaceId: string;
    year: number;
}

export function useUpdateFulfilmentOperator(): {
    updateOperator: (payload: UpdateOperatorPayload) => Promise<boolean>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(false);
    const config = useGetAxiosRequestConfigParams(
        "use-update-fulfilment-operator",
    );
    const { showAxiosErrorMessage } = useErrorNotification();

    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const updateOperator = useCallback(
        async (payload: UpdateOperatorPayload) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    data: {
                        operator: {
                            id: payload.operator.id,
                            name: payload.operator.name,
                            surname: payload.operator.surname,
                        },
                    },
                    lazy: true,
                    method: "post",
                    stateless: true,
                    url: `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/${payload.levyId}/years/${payload.year}/tax-payers/${payload.taxPayerWorkspaceId}/operators`,
                });
                return true;
            } catch (error: any) {
                setError(error);
                return false;
            } finally {
                setLoading(false);
            }
        },
        [config, tenant?.id],
    );

    useEffect(() => {
        if (error) showAxiosErrorMessage(error);
    }, [error, showAxiosErrorMessage]);

    return {
        updateOperator,
        loading: loading,
    };
}
