import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import { LoadingWrapper } from "@vapor/react-contrib-tax-compliance";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import { ReactElement, useCallback, useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import routes from "../../../../../core/commons/routes";
import { FulfilmentDto } from "../../../../../core/dtos/FulfilmentDto";
import { FulfilmentFilterDto } from "../../../../../core/dtos/FulfilmentFilterDto";
import { TaxPayerActivityCountersDto } from "../../../../../core/dtos/TaxPayerActivityCountersDto";
import { TaxPayerDetailsDto } from "../../../../../core/dtos/TaxPayerDto";
import { useGetOperators } from "../../../../../core/usecases/use-get-operators/useGetOperators";
import {
    ActivityCounter,
    ActivityStatus,
} from "../../../../components/activity-counter/ActivityCounter";
import { FulfimentActivityFilter } from "../fulfilment-activity-filter/FulfimentActivityFilter";
import { FulfilmentOperatorAvatar } from "../fulfilment-operator-avatar/FulfilmentOperatorAvatar";
import { FulfilmentTaxPayerListFilter } from "../fulfilment-tax-payer-list-filter/FulfilmentTaxPayerListFilter";

const defaultActivityFilter = {
    closed: false,
    completed: false,
    error: false,
    pending: false,
};

const EmptyData = (): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    return (
        <Box
            sx={{
                textAlign: "center",
                padding: 4,
            }}
        >
            <Typography
                color={theme.palette.primary.textTitleColor}
                variant="h5"
            >
                {t("views.home.components.fulfilment_taxPayer_list.no_data", {
                    defaultValue: "Nessun dato da mostrare",
                })}
            </Typography>
        </Box>
    );
};

export interface FulfilmentTaxPayerListProps {
    isLoading: boolean;
    selectedFulfilment: FulfilmentDto;
    taxPayerActivityCounters: TaxPayerActivityCountersDto;
    taxPayers: TaxPayerDetailsDto[];
}

export const FulfilmentTaxPayerList = ({
    isLoading,
    selectedFulfilment,
    taxPayers,
    taxPayerActivityCounters,
}: FulfilmentTaxPayerListProps): ReactElement => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation("tax-manager-app");

    const [selectedActivityFilter, setSelectedActivityFilter] =
        useState<FulfilmentFilterDto>(defaultActivityFilter);
    const [selectedTaxPayerFilter, setSelectedTaxPayerFilter] =
        useState<TaxPayerDetailsDto | null>(null);
    const [filteredTaxPayers, setFilteredTaxPayers] = useState<
        TaxPayerDetailsDto[]
    >([]);

    const {
        fetch: fetchOperators,
        loading: isLoadingOperators,
        data: availableOperators,
    } = useGetOperators();

    const filterTaxPayers = useCallback((): TaxPayerDetailsDto[] => {
        const selectedFilters = Object.keys(selectedActivityFilter).filter(
            (key) => selectedActivityFilter[key as keyof FulfilmentFilterDto],
        );

        return taxPayers.filter((taxPayer) => {
            const taxPayerFilter = selectedTaxPayerFilter
                ? taxPayer.workspaceId === selectedTaxPayerFilter.workspaceId
                : true;

            if (selectedFilters.length === 0) return taxPayerFilter;

            return (
                selectedFilters.includes(taxPayer.status.toLowerCase()) &&
                taxPayerFilter
            );
        });
    }, [taxPayers, selectedActivityFilter, selectedTaxPayerFilter]);

    useEffect(() => {
        fetchOperators();
    }, [fetchOperators]);

    useEffect(() => {
        setFilteredTaxPayers(filterTaxPayers());

        return () => {
            setFilteredTaxPayers([]);
        };
    }, [filterTaxPayers]);

    return (
        <Stack direction="column" spacing={4}>
            <Stack direction="column" spacing={2} padding={2}>
                <FulfilmentTaxPayerListFilter
                    taxPayers={taxPayers}
                    onFilterChange={setSelectedTaxPayerFilter}
                />
                <FulfimentActivityFilter
                    filters={selectedActivityFilter}
                    hideTotalActivityCounter
                    onClickFilter={(filter) =>
                        setSelectedActivityFilter(filter)
                    }
                    taxPayerActivityCounters={taxPayerActivityCounters}
                />
            </Stack>
            <LoadingWrapper loading={isLoading} variant="skeleton-list">
                {taxPayers && taxPayers.length > 0 ? (
                    <List disablePadding style={{ marginTop: 0 }}>
                        {filteredTaxPayers.length > 0 ? (
                            filteredTaxPayers.map((taxPayer) => (
                                <ListItem
                                    key={taxPayer.workspaceId}
                                    disableGutters
                                    sx={{
                                        "&.MuiListItem-root": {
                                            padding: 0,
                                            border: "1px solid #B9C8D0",
                                            borderLeft: "1px solid transparent",
                                            borderRight:
                                                "1px solid transparent",
                                        },
                                        "&.MuiListItem-root:hover": {
                                            borderLeft: "1px solid transparent",
                                            borderRight:
                                                "1px solid transparent",
                                        },
                                    }}
                                >
                                    <Stack
                                        direction="row"
                                        width="100%"
                                        alignItems="center"
                                        sx={{ px: 2, py: 1 }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                alignItems: "center",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .background,
                                                display: "flex",
                                                justifyContent: "center",
                                                marginRight: 1,
                                                height: 40,
                                                width: 40,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                color={
                                                    theme.palette.primary
                                                        .interactiveDefault
                                                }
                                                icon={faBuilding}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography
                                                    sx={{
                                                        color: theme.palette
                                                            .text.primary,
                                                    }}
                                                    variant="bodyLarge"
                                                >
                                                    {taxPayer.businessName
                                                        ? taxPayer.businessName
                                                        : `${taxPayer.name} ${taxPayer.surname}`}
                                                </Typography>
                                            }
                                            secondary={
                                                <Typography
                                                    sx={{
                                                        color: theme.palette
                                                            .primary
                                                            .textSubduedColor,
                                                    }}
                                                    variant="body"
                                                >
                                                    {t(
                                                        "views.home.components.taxPayer.tax_identifier",
                                                        {
                                                            taxId:
                                                                taxPayer.taxIdentifier.toUpperCase() ||
                                                                taxPayer.vatIdentifier.toUpperCase(),
                                                        },
                                                    )}
                                                </Typography>
                                            }
                                        />
                                        <Stack
                                            alignItems="center"
                                            direction="row"
                                            gap={1}
                                        >
                                            <FulfilmentOperatorAvatar
                                                availableOperators={
                                                    availableOperators || []
                                                }
                                                currentOperator={
                                                    taxPayer.operator
                                                }
                                                isLoading={isLoadingOperators}
                                                selectedFulfilment={
                                                    selectedFulfilment
                                                }
                                                taxPayerWorkspaceId={
                                                    taxPayer.workspaceId
                                                }
                                            />
                                            <ActivityCounter
                                                status={
                                                    ActivityStatus[
                                                        taxPayer.status as keyof typeof ActivityStatus
                                                    ]
                                                }
                                            />
                                            <Divider
                                                sx={{
                                                    ml: 1,
                                                }}
                                                orientation="vertical"
                                                flexItem
                                            />
                                        </Stack>
                                        <IconButton
                                            edge="end"
                                            onClick={() =>
                                                navigate({
                                                    pathname:
                                                        routes.fulfilmentDetail(),
                                                    search: createSearchParams({
                                                        taxPayerWorkspaceId:
                                                            taxPayer.workspaceId,
                                                        levyId: taxPayer.levyId,
                                                        year: dayjs(
                                                            taxPayer.deadline,
                                                        )
                                                            .year()
                                                            .toString(),
                                                    }).toString(),
                                                })
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faArrowRight}
                                                color={
                                                    theme.palette.primary
                                                        .interactiveDefault
                                                }
                                            />
                                        </IconButton>
                                    </Stack>
                                </ListItem>
                            ))
                        ) : (
                            <Box sx={{ px: 2, py: 1 }}>
                                <Typography>
                                    {t(
                                        "views.home.components.fulfilment_taxPayer_list.no_tenant",
                                        { defaultValue: "Nessun contribuente" },
                                    )}
                                </Typography>
                            </Box>
                        )}
                    </List>
                ) : (
                    <EmptyData />
                )}
            </LoadingWrapper>
        </Stack>
    );
};
