import { useTheme } from "@vapor/react-material";
import { toast } from "react-toastify";

export const useSuccessNotification = () => {
    const theme = useTheme();

    const showTextMessage = (message: string) => {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            hideProgressBar: true,
            style: {
                border: "1px solid",
                borderBottomWidth: "4px",
                borderColor: theme.palette.primary.successed,
            },
        });
    };

    return { showTextMessage };
};
