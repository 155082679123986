import { faPlus, faCheck } from "@fortawesome/pro-solid-svg-icons";
import { faBuilding } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Button,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Stack,
    useTheme,
} from "@vapor/react-material";
import { ReactElement, useEffect, useState } from "react";
import { AssociableTaxPayerDto } from "../../../../../core/dtos/AssociableTaxPayerDto";
import { useErrorNotification } from "../../../../../core/hooks/useErrorNotification";
import { useAssociateTaxPayerToFulfilment } from "../../../../../core/usecases/use-associate-tax-payer-to-fulfilment/useAssociateTaxPayerToFulfilment";
import { VaporIcon } from "@vapor/react-icons";

enum AssociationStatus {
    NOT_REQUESTED,
    REQUESTED_SUCCESS,
    REQUESTED_ERROR,
}

export interface FulfilmentAssociationTaxPayerProps {
    levyId: string;
    onAssociateTaxPayer: () => unknown;
    taxPayer: AssociableTaxPayerDto;
    year: number;
}

export const FulfilmentAssociationTaxPayer = ({
    levyId,
    onAssociateTaxPayer,
    taxPayer,
    year,
}: FulfilmentAssociationTaxPayerProps): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");
    const { showTextMessage } = useErrorNotification();

    // states
    const [associationStatus, setAssociationStatus] = useState(
        AssociationStatus.NOT_REQUESTED,
    );

    // use cases
    const { associateTaxPayer, loading: loadingAssociateTaxPayer } =
        useAssociateTaxPayerToFulfilment({
            taxPayerWorkspaceId: taxPayer.workspaceId,
            levyId: levyId,
            year: year,
        });

    // functions
    const onClickAssociateTaxPayerToFulfilment = async () => {
        setAssociationStatus(AssociationStatus.NOT_REQUESTED);
        const success = await associateTaxPayer();

        if (success) {
            setAssociationStatus(AssociationStatus.REQUESTED_SUCCESS);
            onAssociateTaxPayer();
        } else {
            setAssociationStatus(AssociationStatus.REQUESTED_ERROR);
        }
    };

    useEffect(() => {
        if (associationStatus === AssociationStatus.REQUESTED_ERROR) {
            showTextMessage(
                t(
                    "views.home.components.fulfilment_association_taxPayer.errors.associate",
                    {
                        taxPayerName: taxPayer.name,
                        defaultValue:
                            "Errore durante l'associazione del contribuente {{taxPayerName}} all'adempimento",
                    },
                ),
            );
        }
    }, [associationStatus, taxPayer.name, showTextMessage, t]);

    return (
        <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            sx={{
                px: 2,
                py: 1,
            }}
        >
            {/* left */}
            <Stack direction="row" alignItems="center">
                <ListItemIcon
                    sx={{
                        alignItems: "center",
                        borderRadius: "50%",
                        backgroundColor: theme.palette.primary.background,
                        display: "flex",
                        justifyContent: "center",
                        marginRight: 1,
                        height: 40,
                        width: 40,
                    }}
                >
                    <FontAwesomeIcon
                        color={theme.palette.primary.interactiveDefault}
                        icon={faBuilding}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                color: theme.palette.text.primary,
                            }}
                            variant="bodyLarge"
                        >
                            {taxPayer.name}
                        </Typography>
                    }
                    secondary={
                        <Typography
                            sx={{
                                color: theme.palette.primary.textSubduedColor,
                            }}
                            variant="body"
                        >
                            {t(
                                "views.home.components.taxPayer.tax_identifier",
                                {
                                    taxId:
                                        taxPayer.fiscalCode ||
                                        taxPayer.vatNumber,
                                },
                            )}
                        </Typography>
                    }
                />
            </Stack>
            {/* right */}
            <Stack alignSelf="center">
                {associationStatus !== AssociationStatus.REQUESTED_SUCCESS && (
                    <Button
                        variant="outlined"
                        disabled={loadingAssociateTaxPayer}
                        onClick={onClickAssociateTaxPayerToFulfilment}
                        startIcon={
                            <VaporIcon color="interactive" icon={faPlus} />
                        }
                    >
                        <Typography>
                            {associationStatus ===
                                AssociationStatus.NOT_REQUESTED &&
                                t(
                                    "views.home.components.fulfilment_association_taxPayer.actions.associate",
                                    { defaultValue: "Associa" },
                                )}

                            {associationStatus ===
                                AssociationStatus.REQUESTED_ERROR &&
                                t(
                                    "views.home.components.fulfilment_association_taxPayer.actions.retry_associate",
                                    { defaultValue: "Riprova" },
                                )}
                        </Typography>
                        {loadingAssociateTaxPayer && (
                            <CircularProgress
                                color="inherit"
                                sx={{ ml: 1 }}
                                size={16}
                            />
                        )}
                    </Button>
                )}
                {associationStatus === AssociationStatus.REQUESTED_SUCCESS && (
                    <FontAwesomeIcon
                        color={theme.palette.primary.successed}
                        icon={faCheck}
                    />
                )}
            </Stack>
        </Stack>
    );
};
