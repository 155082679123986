import { Box, Stack } from "@vapor/react-material";
import dayjs from "dayjs";
import { ReactElement, useMemo } from "react";
import { AssociableFulfilmentDto } from "../../../../../core/dtos/AssociableFulfilmentDto";
import { AssociableFulfilmentsDto } from "../../../../../core/dtos/AssociableFulfilmentsDto";
import { FulfilmentDto } from "../../../../../core/dtos/FulfilmentDto";
import { FulfilmentsDto } from "../../../../../core/dtos/FulfilmentsDto";
import { FulfilmentNonAssociated } from "../fulfilment-non-associated/FulfilmentNonAssociated";
import { Fulfilment } from "../fulfilment/Fulfilment";

export const groupFulfilmentsByDeadline = <
    T extends FulfilmentsDto | AssociableFulfilmentsDto,
    R extends (FulfilmentDto | AssociableFulfilmentDto)[],
>(
    fulfilmentsDto: T,
): { deadline: Date; fulfilments: R }[] => {
    return Object.keys(fulfilmentsDto.fulfilments)
        .map((deadline) => ({
            deadline: new Date(Number(deadline)),
            fulfilments: fulfilmentsDto.fulfilments[deadline] as R,
        }))
        .sort((a, b) => a.deadline.getTime() - b.deadline.getTime());
};

export interface FulfilmentListProps<
    T extends FulfilmentsDto | AssociableFulfilmentsDto,
> {
    fulfilments: T;
    onClickFulfilment?: (fulfilment: FulfilmentDto) => unknown;
    onClickAssociateTaxPayersToFulfilment: <
        T extends FulfilmentDto | AssociableFulfilmentDto,
    >(
        fulfilment: T,
    ) => unknown;
    onDeleteCustomFulfilment?: () => unknown;
}

export const FulfilmentList = ({
    fulfilments,
    onClickFulfilment,
    onClickAssociateTaxPayersToFulfilment,
    onDeleteCustomFulfilment,
}: FulfilmentListProps<
    FulfilmentsDto | AssociableFulfilmentsDto
>): ReactElement => {
    const groupedFulfilmentsByDeadline = useMemo(
        () =>
            groupFulfilmentsByDeadline<
                FulfilmentsDto | AssociableFulfilmentsDto,
                (FulfilmentDto | AssociableFulfilmentDto)[]
            >(fulfilments),
        [fulfilments],
    );

    return (
        <Stack>
            {groupedFulfilmentsByDeadline.map((group) => (
                <Box
                    key={group.deadline.toISOString()}
                    sx={{ marginBottom: 2 }}
                >
                    {group.fulfilments.map((fulfilment, index) => (
                        <Box key={index}>
                            {Object.hasOwn(fulfilment, "taxPayerCounters") ? (
                                <Fulfilment
                                    taxPayerActivityCounters={{
                                        total: (fulfilment as FulfilmentDto)
                                            .taxPayerCounters.total,
                                        closed: (fulfilment as FulfilmentDto)
                                            .taxPayerCounters.closed,
                                        completed: (fulfilment as FulfilmentDto)
                                            .taxPayerCounters.completed,
                                        error: (fulfilment as FulfilmentDto)
                                            .taxPayerCounters.error,
                                        pending: (fulfilment as FulfilmentDto)
                                            .taxPayerCounters.pending,
                                    }}
                                    date={dayjs(fulfilment.deadline)}
                                    description={fulfilment.description}
                                    isCustomFulfilment={fulfilment.custom}
                                    isFirstFulfilmentInGroup={index === 0}
                                    key={fulfilment.levyId}
                                    levyId={fulfilment.levyId}
                                    onClick={(_) => {
                                        if (onClickFulfilment)
                                            onClickFulfilment(
                                                fulfilment as FulfilmentDto,
                                            );
                                    }}
                                    onClickAssociateTaxPayers={(_) => {
                                        onClickAssociateTaxPayersToFulfilment(
                                            fulfilment,
                                        );
                                    }}
                                    onDeleteCustomFulfilment={
                                        onDeleteCustomFulfilment
                                    }
                                    title={fulfilment.title}
                                />
                            ) : (
                                <FulfilmentNonAssociated
                                    date={dayjs(fulfilment.deadline)}
                                    description={fulfilment.description}
                                    isCustomFulfilment={fulfilment.custom}
                                    key={fulfilment.levyId}
                                    levyId={fulfilment.levyId}
                                    onClickAssociateTaxPayers={(_) => {
                                        onClickAssociateTaxPayersToFulfilment(
                                            fulfilment,
                                        );
                                    }}
                                    onDeleteCustomFulfilment={
                                        onDeleteCustomFulfilment
                                    }
                                    title={fulfilment.title}
                                    header={index === 0}
                                />
                            )}
                        </Box>
                    ))}
                </Box>
            ))}
        </Stack>
    );
};
