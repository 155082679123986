import OneBox from "@drift/oneplatfront";
import { LicenseInfo } from "@mui/x-license-pro";
import dayjs from "dayjs";
import it from "dayjs/locale/it";
import objectSupport from "dayjs/plugin/objectSupport";
import { authService } from "./services/authService";

import { localizationService } from "./services/localizationService";
import { toastContainerService } from "./services/toastContainerService";
import { fulfilmentDetailFeature } from "./ui/views/fulfilment-detail";
import { homeFeature } from "./ui/views/home";
dayjs.extend(objectSupport);

LicenseInfo.setLicenseKey(process.env.REACT_APP_CI_MUI_TOKEN as string);

const appId = process.env.REACT_APP_REGISTRY_ID || "rnd:tax:tax-manager:fe";

const appIdMenuItem =
    process.env.REACT_APP_ID_MENU_ITEM ||
    "9fcfa216-433d-4533-978d-670443eaa170";

const proxyBaseUrl = process.env.REACT_APP_PROXY_BASE_URL || "/api";

dayjs.locale(it); // TODO at the moment set the locale to IT

OneBox.run({
    settings: {
        one: {
            axios: {
                proxy: {
                    baseUrl: proxyBaseUrl,
                },
            },
            appId: appId,
            /*   loading: {
                delay: 200,
            },*/
            box: {
                app: {
                    id: appIdMenuItem, // <-- Id of the Launchpad's Products that we want to preload when the user haven't already picked one
                },
                module: {
                    name: appId, // <-- Name of the module to be developed
                    version: "0.0.1", // <-- Version of the module to be developed
                },
            },
            layout: {
                title: "Adempimenti",
            },
            i18n: {
                options: {
                    // Load all known namespaces
                    // (more namespaces will be loaded on demand)
                    ns: ["react-sdk", "tax-manager-app"],
                    defaultNS: "tax-manager-app",
                    expirationTime: 1000 * 60 * 60, // 1 hour
                },
            },
            auth: {
                token: { verify: true, refresh: true },
            },
            /*    login: {
                target: {
                    params: ["mode=redirect", `appId=${appId}`], // appId used by login app to perform a redirect to tax-compliance after the login
                },
            },*/
        },
    },
    features: [homeFeature, fulfilmentDetailFeature],
    services: [authService, localizationService, toastContainerService],
}).catch(console.error);
