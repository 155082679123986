import { faMagnifyingGlassArrowRight } from "@fortawesome/pro-light-svg-icons";
import {
    faAdd,
    faDownload,
    faEye,
    faList,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Button, Stack, useTheme } from "@vapor/react-material";

interface FulfilmentDetailCTAOptionsProps {
    handleOnClickGoToTaxPayerFulfilments?: () => void;
    handleOnClickAddActivity?: () => void;
    handleOnClickDownloadReport?: () => void;
    handleOnClickPreviewModel?: () => void;
    handleOnClickGoToProcedure?: () => void;
}

export const FulfilmentDetailCTAOptions = ({
    handleOnClickGoToTaxPayerFulfilments,
    handleOnClickAddActivity,
    handleOnClickDownloadReport,
    handleOnClickPreviewModel,
    handleOnClickGoToProcedure,
}: FulfilmentDetailCTAOptionsProps) => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    return (
        <Stack
            direction="column"
            spacing={2}
            alignItems="flex-start"
            marginLeft={4}
        >
            <Button
                onClick={handleOnClickGoToTaxPayerFulfilments}
                style={{
                    cursor: handleOnClickGoToTaxPayerFulfilments
                        ? ""
                        : "not-allowed",
                }}
            >
                <FontAwesomeIcon
                    icon={faList}
                    color={
                        handleOnClickGoToTaxPayerFulfilments
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                />
                <Typography
                    fontSize="titleMedium"
                    color={
                        handleOnClickGoToTaxPayerFulfilments
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                    marginLeft={2}
                >
                    {t(
                        "views.fulfilment_detail.components.fulfilment_detail.activity.cta.taxpayer_fulfilments",
                        {
                            defaultValue:
                                "Tutti gli adempimenti del contribuente",
                        },
                    )}
                </Typography>
            </Button>
            <Button
                onClick={handleOnClickAddActivity}
                style={{
                    cursor: handleOnClickAddActivity ? "" : "not-allowed",
                }}
            >
                <FontAwesomeIcon
                    icon={faAdd}
                    color={
                        handleOnClickAddActivity
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                />
                <Typography
                    fontSize="titleMedium"
                    color={
                        handleOnClickAddActivity
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                    marginLeft={2}
                >
                    {t(
                        "views.fulfilment_detail.components.fulfilment_detail.activity.cta.add_activity_manually",
                        {
                            defaultValue: "Aggiungi Attività manualmente",
                        },
                    )}
                </Typography>
            </Button>
            <Button
                onClick={handleOnClickDownloadReport}
                style={{
                    cursor: handleOnClickDownloadReport ? "" : "not-allowed",
                }}
            >
                <FontAwesomeIcon
                    icon={faDownload}
                    color={
                        handleOnClickDownloadReport
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                />
                <Typography
                    fontSize="titleMedium"
                    color={
                        handleOnClickDownloadReport
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                    marginLeft={2}
                >
                    {t(
                        "views.fulfilment_detail.components.fulfilment_detail.activity.cta.download_report",
                        {
                            defaultValue: "Scarica Report",
                        },
                    )}
                </Typography>
            </Button>
            <Button
                onClick={handleOnClickPreviewModel}
                style={{
                    cursor: handleOnClickPreviewModel ? "" : "not-allowed",
                }}
            >
                <FontAwesomeIcon
                    icon={faEye}
                    color={
                        handleOnClickPreviewModel
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                />
                <Typography
                    fontSize="titleMedium"
                    color={
                        handleOnClickPreviewModel
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                    marginLeft={2}
                >
                    {t(
                        "views.fulfilment_detail.components.fulfilment_detail.activity.cta.preview_report",
                        {
                            defaultValue: "Anteprima modello",
                        },
                    )}
                </Typography>
            </Button>
            <Button
                onClick={handleOnClickGoToProcedure}
                style={{
                    cursor: handleOnClickGoToProcedure ? "" : "not-allowed",
                }}
            >
                <FontAwesomeIcon
                    icon={faMagnifyingGlassArrowRight}
                    color={
                        handleOnClickGoToProcedure
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                />
                <Typography
                    fontSize="titleMedium"
                    color={
                        handleOnClickGoToProcedure
                            ? theme.palette.primary.interactiveDefault
                            : theme.palette.primary.gainsboro
                    }
                    marginLeft={2}
                >
                    {t(
                        "views.fulfilment_detail.components.fulfilment_detail.activity.cta.go_to_procedure",
                        {
                            defaultValue: "Vai alla procedura",
                        },
                    )}
                </Typography>
            </Button>
        </Stack>
    );
};
