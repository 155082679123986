import {
    faBuildings,
    faCircleCheck,
    faClock,
    faEllipsisVertical,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    useTheme,
} from "@vapor/react-material";
import { capitalize } from "lodash";
import { ReactElement, useEffect, useState } from "react";

import { useTranslation } from "@onefront/react-sdk";
import { Dayjs } from "dayjs";
import { v4 } from "uuid";
import { TaxPayerActivityCountersDto } from "../../../../../core/dtos/TaxPayerActivityCountersDto";
import { formatDate } from "../../../../../utils/dateUtils";
import {
    ActivityCounter,
    ActivityStatus,
} from "../../../../components/activity-counter/ActivityCounter";
import { DeleteCustomFulfilment } from "../../../../components/delete-custom-fulfilment/DeleteCustomFulfilment";

interface FulfilmentProps {
    date: Dayjs;
    description: string;
    isCustomFulfilment: boolean;
    isFirstFulfilmentInGroup?: boolean;
    levyId: string;
    onClick: (levyId: string) => void;
    onClickAssociateTaxPayers: (levyId?: string) => unknown;
    onDeleteCustomFulfilment?: () => unknown;
    taxPayerActivityCounters: TaxPayerActivityCountersDto;
    title: string;
}

export const Fulfilment = ({
    taxPayerActivityCounters: activityCounters,
    date,
    description,
    isCustomFulfilment,
    isFirstFulfilmentInGroup: isFirst = false,
    levyId,
    onClick,
    onClickAssociateTaxPayers,
    onDeleteCustomFulfilment,
    title,
}: FulfilmentProps): ReactElement => {
    // states
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    const [isFulfilmentCompleted] = useState(
        activityCounters.error === 0 &&
            activityCounters.closed === 0 &&
            activityCounters.pending === 0 &&
            activityCounters.total > 0,
    );

    const [menuAnchorEl, setMenuAnchorEl] = useState<
        (EventTarget & HTMLButtonElement) | null
    >(null);

    const [fulfilmentTitleMaxWidth, setFulfilmentTitleMaxWidth] =
        useState("0px");
    const [fulfilmentStatusesId] = useState(v4());
    const [fulfilmentContainerId] = useState(v4());

    const onClickFulfilment = () => {
        onClick(levyId);
    };

    const onClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(null);
    };

    const onClickMenuItemAssociateTaxPayers = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.stopPropagation();
        onClickAssociateTaxPayers(levyId);
        handleCloseMenu(event);
    };

    const onClickMenuItemDeleteCustomFulfilment = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        event.stopPropagation();
    };

    const _onDeleteCustomFulfilment = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        handleCloseMenu(event);
        onDeleteCustomFulfilment && onDeleteCustomFulfilment();
    };

    useEffect(() => {
        const handleResize = () => {
            const fulfilmentOffsetLeft =
                (document
                    .getElementById(fulfilmentContainerId)
                    ?.getBoundingClientRect().left || 0) * 2;
            const fulfilmentStatusesWidth =
                document.getElementById(fulfilmentStatusesId)?.clientWidth || 0;

            const widthVar = `calc(98vw - ${fulfilmentOffsetLeft}px - ${fulfilmentStatusesWidth}px)`;

            setFulfilmentTitleMaxWidth(widthVar);
        };
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [fulfilmentContainerId, fulfilmentStatusesId]);

    return (
        <Stack
            direction="column"
            sx={{
                border: "1px solid #B9C8D0",
                borderRadius: isFirst ? "4px 4px 0 0" : undefined,
                borderTop: "none",
            }}
        >
            {isFirst && (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{
                        background: theme.palette.primary.background,
                        borderRadius: isFirst ? "4px 4px 0 0" : undefined,
                        borderTop: "1px solid #B9C8D0",
                        paddingX: 2,
                        paddingY: 1,
                    }}
                >
                    <FontAwesomeIcon
                        icon={faClock}
                        color={theme.palette.primary.textTitleColor}
                    />
                    <Typography
                        variant="subtitle2"
                        color={theme.palette.primary.textTitleColor}
                        sx={{ lineHeight: 1 }}
                    >
                        {t("views.home.components.fulfilment.header.deadline", {
                            defaultValue: "Scadenza:",
                        })}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        color={theme.palette.primary.textTitleColor}
                        sx={{ lineHeight: 1 }}
                    >
                        {formatDate(date)}
                    </Typography>
                </Stack>
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                id={fulfilmentContainerId}
                onClick={onClickFulfilment}
                sx={{
                    border: "1px solid transparent",
                    cursor: "pointer",
                    height: "100%",
                    padding: 1.5,
                    ":hover": {
                        borderColor: "hsl(200, 100%, 42%)",
                    },
                }}
            >
                <Stack
                    maxWidth={fulfilmentTitleMaxWidth}
                    sx={{
                        background: theme.palette.primary.surfaceDefault,
                    }}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        minHeight={32}
                        mr={2}
                    >
                        <Stack
                            sx={{
                                cursor: "pointer",
                                display: "none",
                                width: 1,
                                ":hover": {
                                    width: "100%",
                                    color: "rgb(0, 144, 209)",
                                    display: "inline",
                                },
                                zIndex: "2",
                            }}
                            style={{
                                backgroundColor:
                                    theme.palette.primary.whiteSmoke,
                            }}
                        >
                            <Tooltip title={description}>
                                <Typography
                                    color={theme.palette.primary.textTitleColor}
                                    variant="titleSmall"
                                >
                                    {capitalize(description)}
                                </Typography>
                            </Tooltip>
                        </Stack>
                        <Typography
                            color={theme.palette.text.primary}
                            variant="titleSmall"
                            noWrap
                        >
                            {description}
                        </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                        <Box
                            sx={{
                                background: theme.palette.primary.background,
                                width: "fit-content",
                                padding: 1,
                                borderRadius: "4px",
                            }}
                        >
                            <Stack direction="row" gap={1}>
                                <FontAwesomeIcon
                                    icon={faBuildings}
                                    color={theme.palette.primary.textTitleColor}
                                />
                                <Typography
                                    color={
                                        theme.palette.primary.textSubduedColor
                                    }
                                    variant="subtitle2"
                                    sx={{
                                        lineHeight: 1,
                                    }}
                                >
                                    {activityCounters.total}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                background: theme.palette.primary.background,
                                width: "fit-content",
                                padding: 1,
                                borderRadius: "4px",
                            }}
                        >
                            <Typography
                                color={theme.palette.primary.textSubduedColor}
                                variant="subtitle2"
                                sx={{
                                    lineHeight: 1,
                                }}
                            >
                                {capitalize(title)}
                            </Typography>
                        </Box>
                    </Stack>
                </Stack>
                <Stack
                    alignSelf="center"
                    direction="row"
                    id={fulfilmentStatusesId}
                >
                    {isFulfilmentCompleted ? (
                        <Stack
                            direction="row"
                            p={2}
                            alignItems="center"
                            spacing={1}
                            justifyContent="end"
                        >
                            <FontAwesomeIcon
                                icon={faCircleCheck}
                                color={theme.palette.primary.successed}
                            />
                            <Typography>
                                {t(
                                    "views.home.components.fulfilment.status.completed",
                                    { defaultValue: "Completato" },
                                )}
                            </Typography>
                        </Stack>
                    ) : (
                        <Stack p={2} pt={1} pb={1}>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="end"
                            >
                                <Divider orientation="vertical" flexItem />
                                {activityCounters.completed > 0 && (
                                    <ActivityCounter
                                        count={activityCounters.completed}
                                        status={ActivityStatus.COMPLETED}
                                    />
                                )}
                                {activityCounters.closed > 0 && (
                                    <ActivityCounter
                                        count={activityCounters.closed}
                                        status={ActivityStatus.CLOSED}
                                    />
                                )}
                                {activityCounters.pending > 0 && (
                                    <ActivityCounter
                                        count={activityCounters.pending}
                                        status={ActivityStatus.PENDING}
                                    />
                                )}
                                {activityCounters.error > 0 && (
                                    <ActivityCounter
                                        count={activityCounters.error}
                                        status={ActivityStatus.ERROR}
                                    />
                                )}
                                <Divider orientation="vertical" flexItem />
                            </Stack>
                        </Stack>
                    )}
                    <Stack direction="row" alignItems="center">
                        <IconButton
                            aria-controls="menu"
                            aria-haspopup="true"
                            onClick={onClickMenu}
                            size="small"
                        >
                            <FontAwesomeIcon
                                color={theme.palette.primary.interactiveDefault}
                                icon={faEllipsisVertical}
                                size="xs"
                            />
                        </IconButton>
                        <Menu
                            id="menu"
                            anchorEl={menuAnchorEl}
                            open={!!menuAnchorEl}
                            onClose={handleCloseMenu}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "center",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                        >
                            <MenuItem
                                onClick={onClickMenuItemAssociateTaxPayers}
                            >
                                {t(
                                    "views.home.components.fulfilment.menu_items.associate",
                                    {
                                        defaultValue: "Associa contribuenti",
                                    },
                                )}
                            </MenuItem>
                            {isCustomFulfilment && (
                                <MenuItem
                                    onClick={
                                        onClickMenuItemDeleteCustomFulfilment
                                    }
                                >
                                    <DeleteCustomFulfilment
                                        levyId={levyId}
                                        onCancel={handleCloseMenu}
                                        onDelete={(event) =>
                                            _onDeleteCustomFulfilment(event)
                                        }
                                        sx={{ width: "100%" }}
                                    >
                                        {t(
                                            "views.home.components.fulfilment.menu_items.delete",
                                            {
                                                defaultValue: "Elimina",
                                            },
                                        )}
                                    </DeleteCustomFulfilment>
                                </MenuItem>
                            )}
                        </Menu>
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
