import {
    faXmark,
    faAngleDown,
    faAngleUp,
    faClock,
    faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import Autocomplete from "@vapor/react-material/Autocomplete";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import { Search, VaporIcon } from "@vapor/react-icons";
import {
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
    InputAdornment,
    ListItem,
    Stack,
    TextField,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import {
    ChangeEvent,
    ReactElement,
    SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { FulfilmentStatus } from "../../../../../core/dtos/FulfilmentDetailDto";
import { TaxPayerDto } from "../../../../../core/dtos/TaxPayerDto";
import { FulfilmentFilterSearch } from "../../../../../core/usecases/use-get-fulfilments/useGetFulfilments";
import { FulfilmentListHeaderKPIs } from "../fulfilment-list-header-kpis/FulfilmentListHeaderKPIs";
import { FulfilmentSuggestedAssociation } from "../fulfilment-suggested-association/FulfilmentSuggestedAssociation";

interface FulfilmentListHeaderProps {
    filters: FulfilmentFilterSearch;
    onClickExpiringChip: () => void;
    onClickAddCustomFulfilment: () => unknown;
    onFilterChange: (filters: FulfilmentFilterSearch) => void;
    taxPayers: TaxPayerDto[];
}

export const FulfilmentListHeader = ({
    filters,
    onClickExpiringChip,
    onClickAddCustomFulfilment,
    onFilterChange,
    taxPayers: taxPayersProp,
}: FulfilmentListHeaderProps): ReactElement => {
    const { t } = useTranslation("tax-manager-app");
    const theme = useTheme();

    const [expiringFilter, setExpiringFilter] = useState(false);
    const [openFilters, setOpenFilters] = useState(false);
    const [selectedTaxPayer, setSelectedTaxPayer] =
        useState<TaxPayerDto | null>(null);

    const [taxPayers, setTaxPayers] = useState<TaxPayerDto[]>([]);

    const [
        isSuggestedAssociationDialogOpen,
        setIsSuggestedAssociationDialogOpen,
    ] = useState(false);

    const isSomeFilterSet = useMemo(() => {
        return (
            filters.statuses.length > 0 ||
            !!filters.fulfilmentName ||
            !!filters.taxPayerWorkspaceId
        );
    }, [
        // filters.intermediary,
        filters.fulfilmentName,
        filters.statuses.length,
        filters.taxPayerWorkspaceId,
        // filters.type,
    ]);

    const handleOnChangeSelectedKPIs = useCallback(
        (selectedKPIs: FulfilmentStatus[]) => {
            onFilterChange({
                ...filters,
                statuses: selectedKPIs,
            });
        },
        [filters, onFilterChange],
    );

    const handleOnChangeFulfilmentName = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            onFilterChange({
                ...filters,
                fulfilmentName: event.target.value,
            });
        },
        [filters, onFilterChange],
    );

    const handleTaxPayerChange = useCallback(
        (_: SyntheticEvent<Element, Event>, value: TaxPayerDto | null) => {
            onFilterChange({
                ...filters,
                taxPayerWorkspaceId: value?.workspaceId || undefined,
            });
        },
        [filters, onFilterChange],
    );

    const handleToggleFilters = useCallback(() => {
        setOpenFilters((previous) => !previous);
    }, []);

    const handleResetFilters = useCallback(() => {
        onFilterChange({
            deadlineFrom: dayjs().startOf("month").startOf("day").toDate(),
            deadlineTo: dayjs().endOf("month").endOf("day").toDate(),
            fulfilmentName: "",
            statuses: [],
            taxPayerWorkspaceId: undefined,
            withErrors: false,
            withWarnings: false,
        });
    }, [onFilterChange]);

    useEffect(() => {
        setSelectedTaxPayer(
            taxPayers?.find(
                (taxPayer) =>
                    taxPayer.workspaceId === filters.taxPayerWorkspaceId,
            ) ?? null,
        );
    }, [filters, onFilterChange, taxPayers]);

    useEffect(() => {
        setTaxPayers(
            !selectedTaxPayer ||
                taxPayersProp.find(
                    (taxPayer) =>
                        taxPayer.workspaceId === selectedTaxPayer.workspaceId,
                )
                ? taxPayersProp
                : [selectedTaxPayer, ...taxPayersProp],
        );
    }, [selectedTaxPayer, taxPayersProp]);

    return (
        <Stack spacing={2}>
            <Dialog
                fullWidth
                maxWidth="lg"
                open={isSuggestedAssociationDialogOpen}
                PaperProps={{
                    sx: {
                        maxHeight: "75%",
                        minHeight: "75%",
                    },
                }}
            >
                <DialogTitle>
                    {t(
                        "views.home.components.fulfilment_list_header.suggested_association.dialog.title",
                        {
                            defaultValue: "Associazioni automatiche",
                        },
                    )}
                    <IconButton
                        color="primary"
                        onClick={() => {
                            setIsSuggestedAssociationDialogOpen(false);
                        }}
                    >
                        <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent style={{ padding: 8 }}>
                    <FulfilmentSuggestedAssociation />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setIsSuggestedAssociationDialogOpen(false);
                        }}
                        variant="outlined"
                    >
                        <Typography>
                            {t(
                                "views.home.components.fulfilment_list_header.suggested_association.dialog.close",
                                {
                                    defaultValue: "Chiudi",
                                },
                            )}
                        </Typography>
                    </Button>
                </DialogActions>
            </Dialog>
            <FulfilmentListHeaderKPIs
                accordionRight={
                    <Button
                        onClick={() => {
                            setIsSuggestedAssociationDialogOpen(true);
                        }}
                        variant="outlined"
                    >
                        <Typography>
                            {t(
                                "views.home.components.fulfilment_list_header.suggested_association.associate_fulfilments_btn",
                                {
                                    defaultValue: "Associa automaticamente",
                                },
                            )}
                        </Typography>
                    </Button>
                }
                deadlineFrom={filters.deadlineFrom}
                deadlineTo={filters.deadlineTo}
                kpis={filters.statuses}
                onChangeSelectedKPIs={handleOnChangeSelectedKPIs}
            />
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="row">
                    <TextField
                        placeholder={t(
                            "views.home.components.fulfilment_list_header.filters.fulfilment",
                            { defaultValue: "Cerca per adempimento" },
                        )}
                        sx={{ width: "20vw" }}
                        value={filters.fulfilmentName}
                        onChange={handleOnChangeFulfilmentName}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        sx={{
                                            pointerEvents: "none",
                                            background:
                                                "linear-gradient(-26.56505117707799deg, rgb(0, 195, 234) 0%, rgb(0, 144, 209) 100%)",
                                        }}
                                    >
                                        <Search color="white" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Chip
                        label={t(
                            "views.home.components.fulfilment_list_header.filters.expiring_fulfilments",
                            { defaultValue: "In Scadenza" },
                        )}
                        icon={<VaporIcon icon={faClock} color="info" />}
                        onClick={() => {
                            setExpiringFilter((prevValue) => !prevValue);
                            onClickExpiringChip();
                        }}
                        sx={{
                            marginTop: 0.5,
                            marginLeft: 2,
                        }}
                        variant={expiringFilter ? "outlined" : "filled"}
                        clickable
                    ></Chip>
                    <Button
                        startIcon={
                            <VaporIcon
                                color="interactive"
                                icon={openFilters ? faAngleUp : faAngleDown}
                            />
                        }
                        onClick={handleToggleFilters}
                    >
                        <Typography
                            color={theme.palette.primary.interactiveDefault}
                            variant="bodyLarge500"
                        >
                            {t(
                                "views.home.components.fulfilment_list_header.filters.others_filters",
                                {
                                    defaultValue: "Altri filtri",
                                },
                            )}
                        </Typography>
                    </Button>
                    <Button
                        disabled={!isSomeFilterSet}
                        onClick={handleResetFilters}
                        size="large"
                        startIcon={
                            <VaporIcon
                                color={isSomeFilterSet ? "primary" : "disabled"}
                                icon={faXmark}
                            />
                        }
                    >
                        <Typography
                            color={
                                isSomeFilterSet
                                    ? theme.palette.primary.interactiveDefault
                                    : theme.palette.primary.textHintColor
                            }
                            variant="bodyLarge500"
                        >
                            {t(
                                "views.home.components.fulfilment_list_header.filters.reset",
                                {
                                    defaultValue: "Resetta filtri",
                                },
                            )}
                        </Typography>
                    </Button>
                </Stack>
                <Stack direction="row">
                    <Button
                        onClick={onClickAddCustomFulfilment}
                        startIcon={
                            <VaporIcon color="interactive" icon={faPlus} />
                        }
                    >
                        <Typography
                            color={theme.palette.primary.interactiveDefault}
                            variant="bodyLarge500"
                        >
                            {t(
                                "views.home.components.fulfilment_list_header.filters.add_custom_fulfilment",
                                {
                                    defaultValue: "Nuovo adempimento",
                                },
                            )}
                        </Typography>
                    </Button>
                </Stack>
            </Stack>
            {openFilters && (
                <Stack direction="row" spacing={2}>
                    {/* FIXME: use autocomplete from vapor once clear button gets implemented on single input */}
                    <Autocomplete
                        disabled={taxPayers?.length === 0}
                        getOptionLabel={(option: TaxPayerDto) =>
                            option.businessName
                                ? option.businessName
                                : `${option.name} ${option.surname}`
                        }
                        isOptionEqualToValue={(option, value) => {
                            return option.workspaceId === value.workspaceId;
                        }}
                        filterOptions={(options, { inputValue }) => {
                            return options.filter((option) => {
                                const name = option.businessName
                                    ? option.businessName
                                    : `${option.name} ${option.surname}`;
                                return (
                                    name
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase()) ||
                                    option.taxIdentifier
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase()) ||
                                    option.vatIdentifier
                                        .toLowerCase()
                                        .includes(inputValue.toLowerCase())
                                );
                            });
                        }}
                        noOptionsText={t(
                            "views.home.components.fulfilment_list_header.filters.taxPayer.no_options_text",
                            {
                                defaultValue: "Nessun contribuente trovato",
                            },
                        )}
                        onChange={handleTaxPayerChange}
                        options={taxPayers}
                        renderOption={(props, option) => {
                            return (
                                <ListItem {...props} key={option.workspaceId}>
                                    <Stack>
                                        {option.businessName && (
                                            <Typography>
                                                {option.businessName}
                                            </Typography>
                                        )}
                                        {!option.businessName && (
                                            <Typography>
                                                {option.name} {option.surname}
                                            </Typography>
                                        )}
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .textSubduedColor
                                            }
                                        >
                                            {option.taxIdentifier}
                                        </Typography>
                                    </Stack>
                                </ListItem>
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t(
                                    "views.home.components.fulfilment_list_header.filters.taxPayer.label",
                                    {
                                        defaultValue: "Contribuente",
                                    },
                                )}
                                placeholder={t(
                                    "views.home.components.fulfilment_list_header.filters.taxPayer.placeholder",
                                    {
                                        defaultValue: "Cerca un contribuente",
                                    },
                                )}
                                InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    sx: { width: "20vw" },
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        padding: 0,
                                        paddingLeft: "4px",
                                    },
                                }}
                            />
                        )}
                        value={selectedTaxPayer}
                    />
                </Stack>
            )}
        </Stack>
    );
};
