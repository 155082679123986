import dayjs, { Dayjs } from "dayjs";

export enum Format {
    SHORT_DATE,
    FULL_DATE,
    FULL_DATE_AND_TIME,
}
export const formatDate = (
    date: Dayjs | string | Date,
    format: Format = Format.FULL_DATE,
) => {
    switch (format) {
        case Format.SHORT_DATE:
            return dayjs(date).format("DD/MM/YYYY");
        case Format.FULL_DATE:
            return dayjs(date).format("DD MMMM YYYY");
        case Format.FULL_DATE_AND_TIME:
            return dayjs(date).format("DD MMMM YYYY - HH:mm:ss");
    }
};
