import { useCurrentTenant } from "@drift/oneplatfront";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { BaseUseCaseResponseDto } from "../../dtos/BaseUseCaseResponseDto";
import { SuggestedFulfilmentsDto } from "../../dtos/SuggestedFulfilmentsDto";
import { useGetWithErrorHandler } from "../../hooks/useGetWithErrorHandler";

export function useGetSuggestedFulfilments(): BaseUseCaseResponseDto<
    SuggestedFulfilmentsDto[]
> {
    const { tenant } = useCurrentTenant(true);

    const { fetch, loading, data, error } = useGetWithErrorHandler<
        SuggestedFulfilmentsDto[]
    >(
        `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/suggested-fulfilments`,
        {
            ...useGetAxiosRequestConfigParams("use-get-suggested-fulfilments"),
        },
    );

    return {
        data: data,
        error: error,
        fetch: fetch,
        loading: loading,
    };
}
