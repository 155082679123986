import { useCurrentTenant } from "@drift/oneplatfront";
import { faArrowLeft, faClock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Autocomplete from "@mui/material/Autocomplete";
import { Theme } from "@mui/system";
import { useTranslation } from "@onefront/react-sdk";
import {
    LoadingWrapper,
    VaporPageStickable,
} from "@vapor/react-contrib-tax-compliance";
import { VaporPageStickableDrawerProps } from "@vapor/react-contrib-tax-compliance/VaporPageStickable";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    Divider,
    IconButton,
    Stack,
    TextField,
    useMediaQuery,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import queryString from "query-string";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../../core/commons/routes";
import { TaxPayerDetailsDto } from "../../../core/dtos/TaxPayerDto";
import { useErrorNotification } from "../../../core/hooks/useErrorNotification";
import { useSuccessNotification } from "../../../core/hooks/useSuccessNotification";
import { useCancelLastActivity } from "../../../core/usecases/use-cancel-last-activity/useCancelLastActivity";
import { useCloseFulfilment } from "../../../core/usecases/use-close-fulfilment/useCloseFulfilment";
import { useGetFulfilmentTaxPayers } from "../../../core/usecases/use-get-fulfilment-tax-payers/useGetFulfilmentTaxPayers";
import { useGetFulfilmentActivities } from "../../../core/usecases/use-get-fulfiment-activities/useGetFulfilmentActivities";
import { useGetFulfilmentDetail } from "../../../core/usecases/use-get-fulfiment-detail/useGetFulfilmentDetail";
import { formatDate } from "../../../utils/dateUtils";
import { getTaxPayerIdentifier } from "../../../utils/taxPayer";
import {
    ActivityCounter,
    ActivityStatus,
} from "../../components/activity-counter/ActivityCounter";
import { AddActivity } from "../home/components/add-activity/AddActivity";
import { FulfilmentDetailCTAOptions } from "./FulfilmentDetailCTAOptions";
import { FulfilmentActivityTimeline } from "./components/fulfilment-activity-timeline/FulfilmentActivityTimeline";
import { FulfilmentDetailFooter } from "./components/fulfilment-detail-footer/FulfilmentDetailFooter";

const defaultQueryParms = {
    taxPayerWorkspaceId: "",
    levyId: "",
    year: dayjs().year().toString(),
};

interface FulfilmentDetailQueryParams {
    taxPayerWorkspaceId: string;
    levyId: string;
    year: string;
}

export const FulfilmentDetail = () => {
    const { t } = useTranslation("tax-manager-app");
    const { showTextMessage } = useSuccessNotification();
    const { showTextMessage: showErrorTextMessage } = useErrorNotification();
    const activitiesWidth = document.getElementById(
        "fulfilmentActivitiesContainer",
    )?.clientWidth;
    const isXlScreen = useMediaQuery((theme: Theme) =>
        theme.breakpoints.up(theme.breakpoints.values.xl),
    );
    // states
    const { tenant } = useCurrentTenant(true);

    const [queryParams, setQueryParams] =
        useState<FulfilmentDetailQueryParams>(defaultQueryParms);
    const location = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const [daysLeft, setDaysLeft] = useState(0);
    const [drawerContent, setDrawerContent] =
        useState<VaporPageStickableDrawerProps>();
    const [selectedTaxPayer, setSelectedTaxPayer] =
        useState<TaxPayerDetailsDto | null>(null);
    const [openDrawer, setOpenDrawer] = useState(false);

    // use cases
    const {
        fetch: fetchGetFulfilmentDetail,
        loading: loadingGetFulfilmentDetail,
        data: fulfilmentDetail,
        error: errorGetFulfilmentDetail,
    } = useGetFulfilmentDetail({
        taxPayerWorkspaceId: queryParams.taxPayerWorkspaceId,
        levyId: queryParams.levyId,
        year: Number(queryParams.year),
    });

    const {
        fetch: fetchActivities,
        loading: loadingActivities,
        data: activities,
    } = useGetFulfilmentActivities({
        taxPayerWorkspaceId: selectedTaxPayer
            ? selectedTaxPayer.workspaceId
            : queryParams.taxPayerWorkspaceId,
        levyId: queryParams.levyId,
        year: Number(queryParams.year),
    });

    const { cancelLastActivity, loading: loadingCancelLastActivity } =
        useCancelLastActivity({
            taxPayerWorkspaceId: queryParams.taxPayerWorkspaceId,
            levyId: queryParams.levyId,
            year: Number(queryParams.year),
        });

    const {
        closeFulfilment,
        isFulfilmentCloseable,
        loading: loadingCloseFulfilment,
    } = useCloseFulfilment({
        taxPayerWorkspaceId: queryParams.taxPayerWorkspaceId,
        fulfilment: fulfilmentDetail,
        levyId: queryParams.levyId,
        year: Number(queryParams.year),
    });

    const {
        fetch: fetchFulfilmentTaxPayers,
        data: fulfilmentTaxPayers,
        loading: loadingFulfilmentTaxPayers,
    } = useGetFulfilmentTaxPayers({
        levyId: queryParams.levyId,
        year: Number(queryParams.year),
    });

    // side effects
    useEffect(() => {
        setQueryParams(
            queryString.parse(
                location.search,
            ) as unknown as FulfilmentDetailQueryParams,
        );

        return () => {
            setQueryParams(defaultQueryParms);
        };
    }, [location]);

    useEffect(() => {
        if (
            queryParams.taxPayerWorkspaceId &&
            queryParams.levyId &&
            queryParams.year
        ) {
            fetchGetFulfilmentDetail();
            fetchFulfilmentTaxPayers();
        }
    }, [fetchGetFulfilmentDetail, fetchFulfilmentTaxPayers, queryParams]);

    useEffect(() => {
        if (fulfilmentTaxPayers && fulfilmentTaxPayers.length > 0)
            setSelectedTaxPayer(
                fulfilmentTaxPayers.find((taxPayer) => {
                    return (
                        taxPayer.workspaceId === queryParams.taxPayerWorkspaceId
                    );
                }) || null,
            );
    }, [fulfilmentTaxPayers, queryParams.taxPayerWorkspaceId]);

    useEffect(() => {
        if (selectedTaxPayer) fetchActivities();
    }, [fetchActivities, selectedTaxPayer]);

    useEffect(() => {
        if (fulfilmentDetail) {
            setDaysLeft(dayjs(fulfilmentDetail.deadline).diff(dayjs(), "day"));
        }
        return () => {
            setDaysLeft(0);
        };
    }, [fulfilmentDetail]);

    useEffect(() => {
        return () => navigate(routes.home());
    }, [navigate, tenant]);

    useEffect(() => {
        if (errorGetFulfilmentDetail) {
            window.location.href = "/404";
        }
    }, [errorGetFulfilmentDetail]);

    // functions
    async function handleClickCloseFulfilment() {
        const successfull = await closeFulfilment();
        if (successfull) {
            showTextMessage(
                t(
                    "views.fulfilment_detail.actions.close_fulfilment.successfull",
                    {
                        defaultValue: "Adempimento concluso con successo",
                    },
                ),
            );
            fetchGetFulfilmentDetail();
        }
    }

    async function handleOnClickCancelLastActivity() {
        const response = await cancelLastActivity();
        if (response) {
            if (response.data.isActivityCanceled) {
                showTextMessage(
                    t(
                        "views.fulfilment_detail.actions.cancel_last_activity.successfull",
                        {
                            defaultValue: "Attività annullata con successo",
                        },
                    ),
                );
            } else {
                showErrorTextMessage(
                    t(
                        "views.fulfilment_detail.actions.cancel_last_activity.unsuccessfull",
                        {
                            defaultValue:
                                "Attività non cancellata poichè non creata manualmente",
                        },
                    ),
                );
            }
            if (response.data.isFulfilmentCanceled) {
                setTimeout(() => navigate(routes.home())); // timeout needed to show the successfull alert
            } else {
                fetchGetFulfilmentDetail();
            }
        }
    }

    const handleSelectedTaxPayerChange = useCallback(
        (_: any, value: TaxPayerDetailsDto | null) => {
            setSelectedTaxPayer(value);
        },
        [],
    );

    const onAddActivity = () => {
        fetchActivities();
        onClickCloseDrawer();
    };

    const onClickCloseDrawer = () => {
        setDrawerContent(undefined);
        setOpenDrawer(false);
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleOnClickAddActivity = () => {
        setOpenDrawer(true);
        setDrawerContent({
            content: (
                <AddActivity
                    onClickCancel={onClickCloseDrawer}
                    onAddActivity={onAddActivity}
                ></AddActivity>
            ),
            headerTitle: t(
                "views.fulfilment_detail.drawer.title.new_activity",
                {
                    defaultValue: "Nuova attività",
                },
            ),
            onClickCloseButton: onClickCloseDrawer,
            open: true,
            sxContent: { padding: 0 },
            width: isXlScreen ? "30%" : "40%",
        });
    };

    //TODO: to implement
    // const handleOnClickGoToTaxPayerFulfilments: () => void = () => {};
    // const handleOnClickDownloadReport: () => void = () => {};
    // const handleOnClickPreviewModel: () => void = () => {};
    // const handleOnClickGoToProcedure: () => void = () => {};

    return (
        <>
            {!fulfilmentDetail && !loadingGetFulfilmentDetail && (
                <VaporPageStickable></VaporPageStickable>
            )}
            {(fulfilmentDetail || loadingGetFulfilmentDetail) && (
                <VaporPageStickable
                    sxContent={{
                        p: 3,
                    }}
                    footer={
                        <FulfilmentDetailFooter
                            isFulfilmentCloseable={
                                !loadingGetFulfilmentDetail &&
                                isFulfilmentCloseable()
                            }
                            loading={
                                loadingGetFulfilmentDetail ||
                                loadingCloseFulfilment ||
                                loadingCancelLastActivity
                            }
                            onClickCloseFulfilmentButton={
                                handleClickCloseFulfilment
                            }
                            onClickCancelLastActivity={
                                handleOnClickCancelLastActivity
                            }
                        />
                    }
                    footerDivider
                    headerBottom={
                        <Stack
                            direction="row"
                            spacing={4}
                            p={3}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={10}
                            >
                                <LoadingWrapper
                                    loading={loadingGetFulfilmentDetail}
                                    skeletonProps={{
                                        rowsNumber: 1,
                                        width: 200,
                                    }}
                                    variant="skeleton-list"
                                >
                                    {fulfilmentDetail && (
                                        <Stack
                                            direction="row"
                                            alignItems="center"
                                            gap={3}
                                        >
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}
                                                sx={{
                                                    background:
                                                        theme.palette.primary
                                                            .background,
                                                    p: 1,
                                                    borderRadius: "4px",
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faClock}
                                                    color={
                                                        theme.palette.primary
                                                            .textTitleColor
                                                    }
                                                />
                                                <Typography
                                                    variant="subtitle2"
                                                    color={
                                                        theme.palette.primary
                                                            .textTitleColor
                                                    }
                                                    sx={{ lineHeight: 1 }}
                                                >
                                                    {daysLeft > 0
                                                        ? t(
                                                              daysLeft === 1
                                                                  ? "views.fulfilment_detail.components.fulfilment_detail.deadline.day_left"
                                                                  : "views.fulfilment_detail.components.fulfilment_detail.deadline",
                                                              {
                                                                  date: formatDate(
                                                                      fulfilmentDetail.deadline,
                                                                  ),
                                                                  daysLeft,
                                                                  defaultValue:
                                                                      daysLeft ===
                                                                      1
                                                                          ? "Scadenza: {{date}} ({{daysLeft}} giorno alla scadenza)"
                                                                          : "Scadenza: {{date}} ({{daysLeft}} giorni alla scadenza)",
                                                              },
                                                          )
                                                        : t(
                                                              "views.fulfilment_detail.components.fulfilment_detail.expired",
                                                              {
                                                                  date: formatDate(
                                                                      fulfilmentDetail.deadline,
                                                                  ),
                                                                  defaultValue:
                                                                      "Scadenza: {{date}} (Scaduta)",
                                                              },
                                                          )}
                                                </Typography>
                                            </Stack>
                                            <ActivityCounter
                                                status={
                                                    ActivityStatus[
                                                        fulfilmentDetail?.status as keyof typeof ActivityStatus
                                                    ]
                                                }
                                            />
                                        </Stack>
                                    )}
                                </LoadingWrapper>
                            </Stack>
                            <Stack direction="row">
                                {selectedTaxPayer && (
                                    <Autocomplete
                                        value={selectedTaxPayer || undefined}
                                        disabled={loadingFulfilmentTaxPayers}
                                        loading={loadingFulfilmentTaxPayers}
                                        options={fulfilmentTaxPayers || []}
                                        getOptionLabel={(
                                            option: TaxPayerDetailsDto,
                                        ) => getTaxPayerIdentifier(option)}
                                        isOptionEqualToValue={(
                                            option,
                                            value,
                                        ) => {
                                            return (
                                                option.workspaceId ===
                                                value.workspaceId
                                            );
                                        }}
                                        disableClearable
                                        onChange={handleSelectedTaxPayerChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t(
                                                    "views.fulfilment_detail.components.fulfilment_detail.taxPayerSelect.placeholder",
                                                    {
                                                        defaultValue:
                                                            "Seleziona un contribuente",
                                                    },
                                                )}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    style: {
                                                        width: 210,
                                                        height: 40,
                                                    },
                                                }}
                                                sx={{
                                                    "& .MuiOutlinedInput-root":
                                                        {
                                                            padding: 0,
                                                            paddingLeft: "4px",
                                                        },
                                                }}
                                            />
                                        )}
                                    />
                                )}
                            </Stack>
                        </Stack>
                    }
                    headerBottomDivider
                    headerLeft={
                        <Stack direction="row" spacing={2} alignItems="center">
                            <IconButton
                                edge="end"
                                onClick={() => navigate(routes.home())}
                            >
                                <FontAwesomeIcon
                                    icon={faArrowLeft}
                                    color={
                                        theme.palette.primary.interactiveDefault
                                    }
                                />
                            </IconButton>
                            <LoadingWrapper
                                loading={loadingGetFulfilmentDetail}
                                variant="skeleton-list"
                                skeletonProps={{ rowsNumber: 1, width: 200 }}
                            >
                                {fulfilmentDetail && (
                                    <Stack direction="column">
                                        <Typography
                                            variant="titleSmall"
                                            color={
                                                theme.palette.primary
                                                    .textTitleColor
                                            }
                                        >
                                            {fulfilmentDetail.description}
                                        </Typography>
                                        <Typography
                                            color={
                                                theme.palette.primary
                                                    .textSubduedColor
                                            }
                                            variant="caption"
                                        >
                                            {`${t(
                                                "views.fulfilment_detail.components.fulfilment_detail.fulfilment_type",
                                                {
                                                    defaultValue: "Tipo",
                                                },
                                            )}: ${fulfilmentDetail.title}`}
                                        </Typography>
                                    </Stack>
                                )}
                            </LoadingWrapper>
                        </Stack>
                    }
                    stickyHeader
                    stickyFooter
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Stack
                            direction="column"
                            spacing={2}
                            alignItems="start"
                        >
                            <Box>
                                <Typography
                                    variant="titleMedium"
                                    color={theme.palette.text.primary}
                                >
                                    {t(
                                        "views.fulfilment_detail.components.fulfilment_detail.activity",
                                        { defaultValue: "Attività" },
                                    )}
                                </Typography>
                            </Box>
                            <Stack direction="row" justifyContent="center">
                                <Stack
                                    id="fulfilmentActivitiesContainer"
                                    width={"50vw"}
                                    marginRight={5}
                                >
                                    <Box>
                                        <LoadingWrapper
                                            loading={
                                                loadingGetFulfilmentDetail ||
                                                loadingActivities
                                            }
                                            variant="backdrop"
                                        >
                                            {fulfilmentDetail &&
                                                fulfilmentDetail.activities && (
                                                    <FulfilmentActivityTimeline
                                                        activities={
                                                            activities ||
                                                            fulfilmentDetail.activities
                                                        }
                                                    />
                                                )}
                                        </LoadingWrapper>
                                    </Box>
                                </Stack>
                                <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ borderBottomWidth: 5 }}
                                />
                                <Stack
                                    width={`calc(95vw-${activitiesWidth}px)`}
                                >
                                    <FulfilmentDetailCTAOptions
                                    //TODO: implement
                                    // handleOnClickGoToTaxPayerFulfilments={
                                    //     handleOnClickGoToTaxPayerFulfilments
                                    // }
                                    // TODO: files are missing implementation, also waiting for the BFF api
                                    // handleOnClickAddActivity={
                                    //     handleOnClickAddActivity
                                    // }
                                    // handleOnClickDownloadReport={
                                    //     handleOnClickDownloadReport
                                    // }
                                    // handleOnClickPreviewModel={
                                    //     handleOnClickPreviewModel
                                    // }
                                    // handleOnClickGoToProcedure={
                                    //     handleOnClickGoToProcedure
                                    // }
                                    ></FulfilmentDetailCTAOptions>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </VaporPageStickable>
            )}
            {openDrawer && (
                <VaporPageStickable drawer={drawerContent}></VaporPageStickable>
            )}
        </>
    );
};
