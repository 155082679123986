import { useCurrentTenant } from "@drift/oneplatfront";
import { useRequest } from "@onefront/react-sdk";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { useGetAxiosRequestConfigParams } from "../../commons/useGetAxiosRequestConfigParams";
import { useErrorNotification } from "../../hooks/useErrorNotification";

interface AddCustomFulfilmentPayload {
    deadline: Date;
    fulfilmentName: string;
    workspaceIds: string[];
}

export enum ResponseStatus {
    OK = 201,
    CONFLICT = 409,
    INTERNAL_SERVER_ERROR = 500,
}

export function useAddCustomFulfilment(): {
    addCustomFulfilment: (
        payload: AddCustomFulfilmentPayload,
    ) => Promise<ResponseStatus>;
    loading: boolean;
} {
    const { tenant } = useCurrentTenant(true);
    const [error, setError] = useState<AxiosError>();
    const [loading, setLoading] = useState(false);
    const config = useGetAxiosRequestConfigParams("use-add-custom-fulfilment");
    const { showAxiosErrorMessage } = useErrorNotification();

    const useRequestRef = useRef(useRequest());
    useRequestRef.current = useRequest();

    const upload = useCallback(
        async (payload: AddCustomFulfilmentPayload) => {
            setLoading(true);
            try {
                await useRequestRef.current.fetch({
                    ...config,
                    data: {
                        deadline: dayjs(payload.deadline)
                            .hour(12)
                            .minute(0)
                            .second(0)
                            .millisecond(0)
                            .toISOString(),
                        fulfilmentName: payload.fulfilmentName,
                        workspaceIds: payload.workspaceIds,
                    },
                    lazy: true,
                    method: "post",
                    stateless: true,
                    url: `rnd:tax:tax-manager:bff/api/v1/tenants/${tenant?.id}/fulfilments/custom`,
                });
                return ResponseStatus.OK;
            } catch (error: any) {
                if (error.response?.status === 409) {
                    return ResponseStatus.CONFLICT;
                } else {
                    setError(error);
                    return ResponseStatus.INTERNAL_SERVER_ERROR;
                }
            } finally {
                setLoading(false);
            }
        },
        [config, tenant?.id],
    );

    useEffect(() => {
        if (error) showAxiosErrorMessage(error);
    }, [error, showAxiosErrorMessage]);

    return {
        addCustomFulfilment: upload,
        loading: loading,
    };
}
