import { Theme } from "@mui/system";
import { FulfilmentStatus } from "../core/dtos/FulfilmentDetailDto";

export const getColorAndVariantByFulfilmentStatus = (
    status: FulfilmentStatus,
    theme: Theme,
) => {
    let variant: "ghost" | "default" | "standard" = "ghost";
    let themeColor: string;
    let vaporColor:
        | "blueSapphire"
        | "islamicGreen"
        | "russianViolet"
        | "maximumPurple"
        | "mediumSlateBlue"
        | "prune"
        | "royalFuchsia"
        | "rustyRed";

    switch (status) {
        case FulfilmentStatus.CLOSED:
            themeColor = theme.palette.primary.accentPrune;
            vaporColor = "prune";
            break;
        case FulfilmentStatus.COMPLETED:
            themeColor = theme.palette.primary.accentIslamicGreen;
            vaporColor = "islamicGreen";
            break;
        case FulfilmentStatus.ERROR:
            themeColor = theme.palette.primary.accentRustyRed;
            vaporColor = "rustyRed";
            variant = "standard";
            break;
        case FulfilmentStatus.PENDING:
            themeColor = theme.palette.primary.accentBlueSapphire;
            vaporColor = "blueSapphire";
            break;
        default:
            themeColor = theme.palette.primary.accentBlueSapphire;
            vaporColor = "blueSapphire";
            break;
    }

    return {
        themeColor,
        vaporColor,
        variant,
    };
};
