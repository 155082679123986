import { faFile, faNote } from "@fortawesome/pro-light-svg-icons";
import { faClock } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheck,
    faEllipsisVertical,
    faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TimelineDot, TimelineDotProps } from "@mui/lab";
import { Theme } from "@mui/system";
import { useTranslation } from "@onefront/react-sdk";
import Typography from "@vapor/react-extended/ExtendedTypography";
import {
    Box,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Tooltip,
    useTheme,
} from "@vapor/react-material";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { ReactElement, useState } from "react";
import { FulfilmentActivityDto } from "../../../../../core/dtos/FulfilmentActivityDto";
import { FulfilmentActivityEventType } from "../../../../../core/dtos/FulfilmentActivityEventType";
import { Format, formatDate } from "../../../../../utils/dateUtils";

interface FulfilmentActivityTimelineProps {
    activities: FulfilmentActivityDto[];
    width?: string | number;
}

const translateFulfilmentActivityEventType = (
    event: FulfilmentActivityEventType,
    t: (key: string, options?: any) => string,
) => {
    switch (event) {
        case FulfilmentActivityEventType.LevyClosedV1:
            return t("entity.fulfilment.activity.status.singular.closed", {
                defaultValue: "Chiuso",
            });
        case FulfilmentActivityEventType.LevyCompletedV1:
            return t("entity.fulfilment.activity.status.singular.completed", {
                defaultValue: "Completato",
            });
        case FulfilmentActivityEventType.LevyOnErrorV1:
            return t("entity.fulfilment.activity.status.singular.error", {
                defaultValue: "Errore",
            });
        case FulfilmentActivityEventType.LevyOnGoingV1:
            return t("entity.fulfilment.activity.status.singular.pending", {
                defaultValue: "In lavorazione",
            });
        default:
            return FulfilmentActivityEventType[event];
    }
};

const getTimelineDot = (event: FulfilmentActivityEventType, theme: Theme) => {
    let icon;
    let color: TimelineDotProps["color"];
    switch (event) {
        case FulfilmentActivityEventType.LevyOnErrorV1:
            icon = (
                <FontAwesomeIcon
                    color={theme.palette.primary.accentRustyRed}
                    icon={faXmark}
                    size="sm"
                />
            );
            color = "error";
            break;
        case FulfilmentActivityEventType.LevyOnGoingV1:
            icon = (
                <FontAwesomeIcon
                    color={theme.palette.primary.gainsboro}
                    icon={faClock}
                    size="sm"
                />
            );
            color = "grey";
            break;
        default:
            icon = <FontAwesomeIcon icon={faCheck} size="sm" />;
            color = "primary";
    }

    return (
        <TimelineDot
            color={color}
            variant="outlined"
            sx={{
                width: 24,
                height: 24,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                my: 0,
            }}
        >
            {icon}
        </TimelineDot>
    );
};

export const FulfilmentActivityTimeline = ({
    activities,
    width,
}: FulfilmentActivityTimelineProps): ReactElement => {
    const theme = useTheme();
    const { t } = useTranslation("tax-manager-app");

    const [menuAnchorEl, setMenuAnchorEl] = useState<
        (EventTarget & HTMLButtonElement) | null
    >(null);

    const handleCloseMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(null);
    };

    const onClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
    };

    return (
        <Stack>
            {activities
                .sort((activity1, activity2) =>
                    dayjs(activity2.timestamp).diff(dayjs(activity1.timestamp)),
                )
                .map((activity, index) => (
                    <Stack key={index}>
                        <Stack
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                            gap={4}
                            border="1px solid"
                            p={3}
                            maxWidth={width ? width : "60vw"}
                            sx={{
                                border: `1px solid ${theme.palette.primary.columbiaBlue}`,
                                borderRadius: "4px",
                            }}
                        >
                            <Stack
                                flexDirection="row"
                                gap={2.5}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Tooltip
                                        title={translateFulfilmentActivityEventType(
                                            activity.type,
                                            t,
                                        )}
                                        placement="top"
                                    >
                                        {getTimelineDot(activity.type, theme)}
                                    </Tooltip>
                                </Box>
                                <Stack gap={0.5}>
                                    <Typography
                                        sx={{
                                            cursor: "pointer",
                                            ":hover": {
                                                color: "rgb(0, 144, 209)",
                                            },
                                            wordWrap: "break-word",
                                        }}
                                        color={theme.palette.text.primary}
                                        variant="titleSmall"
                                    >
                                        {capitalize(activity.description)}
                                    </Typography>
                                    {activity.operator && (
                                        <Typography
                                            variant="body"
                                            color={
                                                theme.palette.primary
                                                    .textSubduedColor
                                            }
                                        >
                                            {`${activity.operator.givenName} ${activity.operator.familyName}`}
                                        </Typography>
                                    )}
                                    <Typography
                                        variant="bodySmall"
                                        color={
                                            theme.palette.primary.textTitleColor
                                        }
                                    >
                                        {formatDate(
                                            activity.timestamp,
                                            Format.SHORT_DATE,
                                        )}
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Stack
                                direction="column"
                                justifyContent={"space-between"}
                            >
                                <Stack
                                    direction="row"
                                    justifyContent={"center"}
                                >
                                    {/* TODO: render Note and File icons according to the fulfilment properties */}
                                    <Tooltip
                                        title={t(
                                            "views.fulfilment_detail.drawer.content.new_activity.activity_file",
                                            { defaultValue: "File" },
                                        )}
                                        placement="top"
                                    >
                                        <Box
                                            sx={{ cursor: "pointer" }}
                                            margin={1}
                                        >
                                            <FontAwesomeIcon
                                                icon={faFile}
                                                color={
                                                    theme.palette.primary
                                                        .textTitleColor
                                                }
                                                size="lg"
                                            />
                                        </Box>
                                    </Tooltip>
                                    <Tooltip
                                        title={t(
                                            "views.fulfilment_detail.drawer.content.new_activity.activity_note",
                                            { defaultValue: "Note" },
                                        )}
                                        placement="top"
                                    >
                                        <Box
                                            sx={{ cursor: "pointer" }}
                                            margin={1}
                                        >
                                            <FontAwesomeIcon
                                                icon={faNote}
                                                color={
                                                    theme.palette.primary
                                                        .textTitleColor
                                                }
                                                size="lg"
                                            />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                >
                                    <IconButton
                                        aria-controls="menu"
                                        aria-haspopup="true"
                                        onClick={onClickMenu}
                                        size="small"
                                    >
                                        <FontAwesomeIcon
                                            color={
                                                theme.palette.primary
                                                    .interactiveDefault
                                            }
                                            icon={faEllipsisVertical}
                                            size="xl"
                                            fontWeight={400}
                                        />
                                    </IconButton>
                                    <Menu
                                        id="menu"
                                        anchorEl={menuAnchorEl}
                                        open={!!menuAnchorEl}
                                        onClose={handleCloseMenu}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center",
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "right",
                                        }}
                                    >
                                        {/* TODO: implement Note and File according to the fulfilment properties */}
                                        <MenuItem onClick={() => {}}></MenuItem>
                                    </Menu>
                                </Stack>
                            </Stack>
                        </Stack>
                        {activities.length > index + 1 && (
                            <Box
                                sx={{
                                    marginLeft: 3,
                                    minHeight: "24px",
                                    height: "100%",
                                    width: "1px",
                                    backgroundColor:
                                        theme.palette.primary.columbiaBlue,
                                }}
                            />
                        )}
                    </Stack>
                ))}
        </Stack>
    );
};
